import { useEffect, useState, Fragment } from "react";
import { MdDelete, MdEdit } from "react-icons/md";

// UI IMPORT
import { Button, Loader, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { responseToaster } from "../../helperFunctions";
import { FILTER } from "../../constant";
import DeleteModal from "../common/DeleteModal";
import {
  useDeleteAdminMutation,
  useGetAdminsMutation,
  useLogoutAllAdminMutation,
  useResetAdminPasswordMutation,
} from "./adminApi";
import { setCurrentAdmin, useAdmins } from "./adminSlice";
import AdminModal from "./AdminModal";
import PasswordModal from "./PasswordModal";

// THIRD - PARTY IMPORT
import { MdLockReset } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../utills/hooks";
import { useUser } from "../auth/authSlice";
import ConfirmModal from "../common/ConfirmModal";

const Admin = () => {
  const userData: any = useUser();

  const admins: any = useAdmins();
  const navigate = useNavigate();
  const route = useLocation()?.pathname;
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState(FILTER);
  const [deleteId, setDeleteId] = useState<any>(false);
  const [resetPasswordId, setResetPasswordId] = useState<any>(false);
  const [modalDetails, setModalDetails] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [passwordModal, SetPasswordModal] = useState(false);
  const [isOpenConfirmation, setisOpenConfirmation] = useState<Boolean>(false);
  const [isPwdLoading, setIsPwdLoading] = useState(false);
  const [isCopy, setIsCopy] = useState<any>();
  const [getAdmins, { isLoading }] = useGetAdminsMutation();
  const [deleteAdmin, { isLoading: isDeleteLoading }] =
    useDeleteAdminMutation();
  const [resetAdminPassword] = useResetAdminPasswordMutation();

  const [logoutAllAdmin, { isLoading: isLogoutAllAdminLoading }] =
    useLogoutAllAdminMutation();

  const onResetPass: any = async () => {
    try {
      const id = resetPasswordId;
      SetPasswordModal(id);
      setIsPwdLoading(true);
      const res: any = await resetAdminPassword({ id }).unwrap();
      if (res) {
        setIsCopy(res?.data);
        responseToaster(res);
        setIsPwdLoading(false);
      }
    } catch (err: any) {
      setIsPwdLoading(false);
      console.error("Error =-=>", err);
    } finally {
      setResetPasswordId(false);
    }
  };

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "Name",
      name: "name",
      sortable: false,
      sortableKey: "name",
    },
    {
      title: "User name",
      name: "username",
      sortable: false,
      sortableKey: "username",
    },
    {
      title: "Roles",
      name: "roles",
      sortable: false,
      sortableKey: "roles",
      Cell: (item: any) => {
        return (
          <>
            {item?.roles?.map((item: any, index: any) => {
              return (
                <div className="bg-ev-green text-white py-1 px-2 rounded-md mb-1 w-fit">
                  {item?.name}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Email",
      name: "email",
      sortable: false,
      sortableKey: "email",
    },
  ];

  if (
    userData?.access_level?.ADMIN_EDIT ||
    userData?.access_level?.ADMIN_CHANGE_PASSWORD ||
    userData?.access_level?.ADMIN_DELETE
  ) {
    const newCol: any = {
      title: "Actions",
      name: "actions",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {userData?.access_level?.ADMIN_CHANGE_PASSWORD ? (
                <Button
                    title="Reset Password"
                    className="!h-7 w-8 !p-0"
                    variant="Transaparent"
                    onClick={() => {
                      setResetPasswordId(item?.id);
                    }}
                >
                  {isPwdLoading && passwordModal === item?.id ? (
                      <Loader loaderClassName="create-edit-bonus" />
                  ) : (
                      <MdLockReset />
                  )}
                </Button>
            ) : null}
            {userData?.access_level?.ADMIN_EDIT ? (
              <Button
                className="!h-7 w-8 !p-0"
                title="Edit"
                onClick={() => {
                  dispatch(setCurrentAdmin(item));
                  navigate(`${route}/${item?.id}`);
                }}
              >
                <MdEdit />
              </Button>
            ) : null}
            {userData?.access_level?.ADMIN_DELETE ? (
              <Button
                title="Delete"
                variant="Error"
                className="!h-7 w-8 !p-0"
                onClick={() => {
                  setDeleteId(item?.id);
                }}
              >
                <MdDelete />
              </Button>
            ) : null}
          </div>
        );
      },
    };
    columns?.push(newCol);
  }

  const onGetData = async () => {
    try {
      const payload: any = {
        ...filter,
      };
      delete payload["isFilter"];
      const res: any = await getAdmins(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    setTableData(admins?.data?.data?.length ? [...admins?.data?.data] : []);
  }, [admins]);

  const onDelete = async () => {
    try {
      const payload = {
        id: deleteId,
      };
      await deleteAdmin(payload).unwrap();
      setDeleteId(false);
      setFilter({ ...filter, isFilter: false });
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const logoutAll = async () => {
    try {
      const res = await logoutAllAdmin().unwrap();
      responseToaster(res);
      setisOpenConfirmation(false);
    } catch (err) {}
  };

  return (
    <>
      <div className="">
        <div className="flex items-centere justify-between pb-3">
          {/* <ControlledInput
            name="searchData"
            className="w-100"
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          /> */}
          <div className="flex gap-2">
            {/* <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" /> Apply Filter
            </Button> */}
            {userData?.access_level?.ADMIN_CREATE ? (
              <Button
                type="button"
                onClick={() => {
                  navigate(`${route}/create`);
                }}
              >
                Add Admin
              </Button>
            ) : null}

            {/* <Export
              variant="Transaparent"
              filename="download.csv"
              data={
                admins?.data?.map((item: any) => ({
                  ...item,
                  status: item?.status ? "Active" : "Deactive",
                  created_at: dateFormatter(item?.created_at),
                  expiry_date: dateFormatter(item?.expiry_date),
                  bonus_amount: seperator(item?.bonus_amount),
                })) || []
              }
              headers={
                columns?.map((val) => ({
                  label: val?.title,
                  key: val?.name,
                })) || []
              }
            >
              Export
            </Export> */}
          </div>
          {userData?.access_level?.ADMIN_LOGOUT_ALL_DEVICE ? (
            <div>
              <Button
                type="button"
                variant="Error"
                onClick={() => setisOpenConfirmation(true)}
              >
                Logout All Admin
              </Button>
            </div>
          ) : null}
        </div>

        {/* {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="domainId"
                label="Domain"
                options={prepareDomainOptions()}
                register={register}
              />
              <ControlledDatePicker
                inputClassName="!w-52"
                name="createdStartDate"
                label="Bonus Create Date"
                placeholder="Select Create Date"
                selectsRange
                maxDate={new Date()}
                control={control}
              />
              <ControlledDatePicker
                name="startDate"
                label="Bonus Start Date"
                placeholder="Select Bonus Start Date"
                selectsRange
                control={control}
              />
              <ControlledDatePicker
                name="endDate"
                label="Bonus End Date"
                placeholder="Select Bonus End Date"
                selectsRange
                control={control}
              />
              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOptions}
                register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )} */}

        <div>
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={tableData}
              isExpendable={false}
              count={admins?.total_item || 0}
              pagination={filter}
              setData={setTableData}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <DeleteModal
        isLoading={isLogoutAllAdminLoading}
        isOpen={!!isOpenConfirmation}
        text="Are you sure?"
        onHide={() => {
          setisOpenConfirmation(false);
        }}
        onDoneClick={() => {
          logoutAll();
        }}
      />
      <DeleteModal
        isLoading={isDeleteLoading}
        isOpen={!!deleteId}
        onHide={() => {
          setDeleteId(false);
        }}
        onDoneClick={() => onDelete()}
      />
      <AdminModal
        isOpen={!!modalDetails}
        data={modalDetails}
        onHide={() => setModalDetails(false)}
        onGet={() => setFilter({ ...filter })}
      />
      <PasswordModal
        isOpen={!!passwordModal && !isPwdLoading}
        onHide={() => {
          SetPasswordModal(false);
          setIsCopy({});
        }}
        isCopy={isCopy}
      />
      <ConfirmModal
          isLoading={isPwdLoading}
          isOpen={!!resetPasswordId}
          onHide={() => {
            setResetPasswordId(false);
          }}
          onDoneClick={() => onResetPass()}
          text="Are you sure you want to reset password this?"
      />
    </>
  );
};

export default Admin;
