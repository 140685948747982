import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import {
  Button,
  ControlledInput,
  Select,
  Table,
  TableRow,
  getStatusTypes,
  Input,
  Export,
} from "../../ui";
import { CiFilter } from "react-icons/ci";

// PROJECT IMPORT
import { useGetUserMutation } from "./userApi";
import { useUserData } from "./userSlice";

// THIED - PARTY IMPORT
import { useForm } from "react-hook-form";
import { responseToaster, seperator } from "../../helperFunctions";
import BlackListModal from "./BlackListModal";
import CustomAmountModal from "./CustomAmountModal";
import ToggleSwitch from "../../ui/forms/ToggleSwitch";
import BlockConformationModal from "./BlockConformationModal";
import UploadUserCsvModal from "./UploadUserCsvModal";
import { MdOutlineFileUpload } from "react-icons/md";
import LoyaltyConformationModal from "./LoyaltyConformationModal";

const initialValues = {
  player_id: "All",
  status: "",
};

const statusOptions = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 3,
    label: "Block",
  },
  {
    value: 5,
    label: "Suspend",
  },
];

const blockOptions = [
    {
        value: 1,
        label: "Block",
    },
    {
        value: 0,
        label: "Unblock",
    },
];

const limitOptions = [
  { value: 15, label: 15 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 300, label: 300 },
  { value: 400, label: 400 },
  { value: 500, label: 500 },
];

const FILTER = {
  page_no: 1,
  limit: 15,
  isFilter: false,
};
const blacklistOption = [
  {
    value: 1,
    label: "Block",
    activeClassName: "bg-[#ef4444]"
  },
  {
    value: 0,
    label: "Unblock",
    activeClassName: "bg-[#15cd2b]" 
  },
];

const User = () => {
  const userData: any = useUserData();
  const [filter, setFilter] = useState(FILTER);
  const [showDiv, setShowDiv] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const [getUser, { isLoading }] = useGetUserMutation();
  const [isBlackListModalOpen, setIsBlackListModalOpen] = useState<any>(false);
  const [blackListPayload, setBlackListPayload] = useState({});
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<any>(false);
  const [blockPayload, setBlockPayload] = useState({});
  const [isLoyaltyModalOpen, setIsLoyaltyModalOpen] = useState<any>(false);
  const [loyaltyPayload, setLoyaltyPayload] = useState({});
  const [isUploadCSVModalOpen, setIsUploadCSVModalOpen] = useState<any>(false);

  const [tableData, setTableData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const isInitialMount = useRef(true);
  const [isCustomAmountModalOpen, setIsCustomAmountModalOpen] =
    useState<any>(false);
  const [amountMethod, setAmountMethod] = useState<any>("");
  const [customData, setCustomData] = useState({
    id: 0,
    amount: 0,
  });
  const customAmount = useRef(0);

  useEffect(() => {
    setTableData(userData?.data?.length ? [...userData?.data] : []);
  }, [userData]);

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "Player ID",
      name: "player_id",
      sortable: false,
      sortableKey: "player_id",
    },
    {
      title: "Username",
      name: "username",
      sortable: false,
      sortableKey: "username",
    },
    {
      title: "Bonus / Cashback",
      name: "player_id",
      Cell: (item: any) => {
        return (
          <div className="flex items-start gap-2 border-ev-primary">
            <Input
              className="!w-[120px]"
              name="customBonus"
              placeholder="Amount"
              type="number"
              onChange={(e) => {
                customAmount.current = e.target.value;
              }}
            />
            <Button
              title="Deposit"
              className="!w-auto !h-[38px] bg-chatlook-green !border-0 -ms-1 ruppebtn"
              onClick={() => {
                if (customAmount.current > 0) {
                  setIsCustomAmountModalOpen(true);
                  setAmountMethod("deposit");
                  setCustomData({
                    id: item.player_id,
                    amount: customAmount.current,
                  });
                }
              }}
            >
              D
            </Button>
            <Button
              title="Withdraw"
              className="!w-auto !h-[38px] bg-chatlook-red !border-0 -ms-1 ruppebtn !text-ev-secondary"
              variant="Transaparent"
              onClick={() => {
                if (customAmount.current > 0) {
                  setIsCustomAmountModalOpen(true);
                  setAmountMethod("withdraw");
                  setCustomData({
                    id: item.player_id,
                    amount: customAmount.current,
                  });
                }
              }}
            >
              W
            </Button>
          </div>
        );
      },
    },
    {
      title: "Player Balance",
      name: "my_balance",

      Cell: (item: any) => {
        return (
          <div className="flex flex-col justify-center items-start gap-1">
            <div>
              <span className="font-semibold">Cash</span>:{" "}
              <span>&nbsp;&nbsp;&nbsp;{seperator(item?.my_balance?.cash)}</span>
            </div>
            <div>
              <span className="font-semibold">Sports</span>:{" "}
              <span>{seperator(item?.my_balance?.sports)}</span>
            </div>
            <div>
              <span className="font-semibold">Casino</span>:{" "}
              <span>{seperator(item?.my_balance?.casino)}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Account Status",
      name: "account_status",
      sortable: false,
      sortableKey: "account_status",
      Cell: (item: any) => {
        return (
          <span className={`${getStatusTypes(item?.account_status)}`}>
            {item?.account_status === 1 ? "Active" : item?.account_status === 3 ? "Block" : "Suspend"}
          </span>
        );
      },
    },
    // {
    //   title: "BlackList",
    //   name: "blacklist",
    //   Cell: (data: any) => {
    //     return (
    //       <>
    //         <ToggleSwitch
    //           options={blacklistOption}
    //           value={1}
    //           onChange={async (value: any) => {
    //             const payload = {
    //               status: value,
    //               userId: data?.id,
    //             };
    //             setBlackListPayload(payload);
    //             setIsBlackListModalOpen(true);
    //             // await onUpdateStatus(payload);
    //           }}
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      title: "Block/UnBlock",
      name: "block",
      Cell: (data: any) => {
        return (
          <>
            <ToggleSwitch
              options={blacklistOption}
              value={data?.is_block ? 1 : 0}
              onChange={async (value: any) => {
                const payload = {
                  is_block: value,
                  id: data?.player_id,
                };
                setBlockPayload(payload);
                setIsBlockModalOpen(true);
                // await onUpdateStatus(payload);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Loyalty Program",
      name: "is_block_loyalty_cashback",
      Cell: (data: any) => {
        return (
            <>
              <ToggleSwitch
                  options={blacklistOption}
                  value={data?.is_block_loyalty_cashback ? 1 : 0}
                  onChange={async (value: any) => {
                    const payload = {
                      is_block: value,
                      id: data?.player_id,
                    };
                    setLoyaltyPayload(payload);
                    setIsLoyaltyModalOpen(true);
                    // await onUpdateStatus(payload);
                  }}
              />
            </>
        );
      },
    },
  ];

  const { register, handleSubmit, setValue, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const onGetData = async () => {
    try {
      const values = getValues();
      const { searchData, searchReferralCode, ...rest } = values;
      const payload: any = {
        ...filter,
        filter_data: {
          searchData: searchTerm,
          searchReferralCode: searchCode,
          ...rest,
        },
      };
      delete payload["isFilter"];
      const res: any = await getUser(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchCode]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (showMsg) {
        setShowMsg(false);
      }
    }, 2000);
    return () => clearInterval(IntervalClick);
  }, [showMsg]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-center justify-between pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
                onClick={() => setIsUploadCSVModalOpen(!isUploadCSVModalOpen)}
            >
             <MdOutlineFileUpload className="me-1" size={24} /> User Block
            </Button>
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv);
              }}
            >
              <CiFilter className="text-lg" />
            </Button>
            <Export
              variant="Transaparent"
              filename="download.csv"
              isLoading={isLoading}
              data={
                userData?.data?.map((item: any) => ({
                  ...item,
                  my_balance: seperator(item?.my_balance?.cash),
                  account_status:
                    item?.account_status === 1 ? "Active" : "Deactive",
                })) || []
              }
              headers={
                columns?.map((val) => ({
                  label: val?.title,
                  key: val?.name,
                })) || []
              }
            >
              Export
            </Export>
          </div>
        </div>
        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              {/*<Select*/}
              {/*  name="player_id"*/}
              {/*  label="Player"*/}
              {/*  options={preparePlayerOptions()}*/}
              {/*  register={register}*/}
              {/*/>*/}
              <Select
                name="status"
                label="Status"
                placeholder="Select status"
                options={statusOptions}
                register={register}
              />
              <Select
                name="is_block"
                label="Block/UnBlock"
                placeholder="Select status"
                options={blockOptions}
                register={register}
              />
              <Select
                name="is_block_loyalty_cashback"
                label="Loyalty Program"
                placeholder="Select status"
                options={blockOptions}
                register={register}
              />
              <ControlledInput
                name="searchReferralCode"
                className="w-100"
                rightIcon={searchCode !== "" ? "Closeicon" : null}
                righticonClick={() => setSearchCode("")}
                placeholder="Enter Referral Code"
                control={control}
                value={searchCode}
                type="text"
                autoComplete="off"
                onChange={(event) => {
                    setSearchCode(event.target.value);
                }}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                  setSearchCode("");
                  setValue("searchReferralCode", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}
        <div className="App mt-3 tableborder bg-chatlook-white">
          <Table
            columns={columns}
            isLoading={isLoading}
            data={userData?.data}
            isExpendable={false}
            setData={setTableData}
            count={userData?.total_item || 0}
            pagination={filter}
            limitOptions={limitOptions}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
      <UploadUserCsvModal
          isOpen={isUploadCSVModalOpen}
          onClose={() => setIsUploadCSVModalOpen(false)}
        onGet={() => onGetData()}
        setShowMsg={setShowMsg}
          showMsg={showMsg}
        />
      <BlackListModal
        isOpen={isBlackListModalOpen}
        onClose={() => {
          setIsBlackListModalOpen(false);
          setBlackListPayload({});
        }}
        blackListPayload={blackListPayload}
        userData={userData}
      />
      <BlockConformationModal
        isOpen={isBlockModalOpen}
        onClose={() => {
          setIsBlockModalOpen(false);
          setBlockPayload({});
        }}
        onGet={() => onGetData()}
        blockPayload={blockPayload}
        userData={userData}
      />
      <LoyaltyConformationModal
          isOpen={isLoyaltyModalOpen}
          onClose={() => {
            setIsLoyaltyModalOpen(false);
            setLoyaltyPayload({});
          }}
          onGet={() => onGetData()}
          blockPayload={loyaltyPayload}
          userData={userData}
      />
      <CustomAmountModal
        isOpen={isCustomAmountModalOpen}
        amountMethod={amountMethod}
        onClose={() => {
          setIsCustomAmountModalOpen(false);
          setAmountMethod("");
          setCustomData({
            id: 0,
            amount: 0,
          });
          customAmount.current = 0;
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        tobeCustomData={customData}
      />
    </>
  );
};

export default User;
