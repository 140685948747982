import closeButton from "../../assets/images/close-button.svg";
import { useRef } from "react";
import {ordinalToCardinal, seperator} from "../../helperFunctions";

const CashbackModal = (props: any) => {
  const { isOpen, onClose, data } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  if (!isOpen) return null;

  return (
      <form className="fixed inset-0 z-50 flex items-center justify-center">
        <div
            className="modal-overlay absolute w-full h-full  bg-gray-900 opacity-50"
            onClick={() => {
              onClose();
            }}
        />

        <div className="modal-container bg-white w-6/12 md:w-8/12 sm:w-11/12 xs:w-11/12 mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
          <div
              className="modal-content p-5 text-left overflow-scroll max-h-[98vh] bg-chatlook-primary border rounded-[8px]"
              ref={tableRef}
          >
            <div className="flex justify-end items-center">
              <div
                  className="modal-close cursor-pointer z-50  flex items-center gap-2"
                  onClick={onClose}
              >
                <img src={closeButton} alt="Logo" />
              </div>
            </div>
            <div className="flex flex-col items-end justify-start mt-2">
              <p className="block-title mb-2 text-ev-secondary">Last Updated</p>
              <p className="block-data">{data?.updated_at || "-"}</p>
              <p className="block-data-small">
                {data?.updated_at_readable
                    ? `(${data.updated_at_readable})`
                    : null}
              </p>
            </div>
            <div className="">
              <div className="mt-2">
                <h4 className="text-lg font-semibold  md:text-base text-ev-white">
                  Cashback Details
                </h4>
              </div>
              <div className="grid grid-cols-4 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">ID</p>
                  <p className="block-data">{ data?.id }</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Cashback Type</p>
                  <p className="block-data capitalize">{data?.type}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Cashback Name</p>
                  <p className="block-data capitalize">{data?.name}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code</p>
                  <p className="block-data">{data?.promo_code}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Domain</p>
                  <p className="block-data">{data?.domain_name}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Is Only For New Player?</p>
                  {data?.is_only_new_player === 1 ?
                      <p className="block-data">Yes</p>
                      : <p className="block-data">No</p>}
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">VIP Levels</p>
                  {data?.vip_user_level ?
                      <p className="block-data capitalize">{data?.vip_user_level}</p>
                      : <p className="block-data">All</p>}
                </div>
                {data?.type === "deposit" ? (
                    <>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Cashback Sub Type</p>
                        <p className="block-data capitalize">{data?.sub_type}</p>
                      </div>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Deposit Amount</p>
                        {data?.max_deposit <= 0 ?
                            <p className="block-data">{`${seperator(data?.min_deposit)}+`}</p>
                            : <p className="block-data">{`${seperator(data?.min_deposit)} - ${seperator(data?.max_deposit)}`}</p>}
                        {data?.sub_type === 'number' && data?.max_usage !== -2 ? <span className="block-data">({data?.frequency_settings} <sup>times</sup>)</span> : null}
                      </div>
                    </>
                ) : null}
                {data?.criteria_name ? (
                    <>
                      <div>
                        <p className="block-title mb-2 text-ev-secondary">Criteria Name</p>
                        {data?.criteria_name === "fdeposit" ?
                            <p className="block-data">Friend 1st Deposit</p>
                            : <p className="block-data capitalize">{data?.criteria_name}</p> }
                      </div>
                      {data?.type === "deposit" ? (
                          <div>
                            <p className="block-title mb-2 text-ev-secondary">Criteria Range</p>
                            {data?.criteria_range_to <= 0 ?
                                <p className="block-data">{`${seperator(data?.criteria_range_from)}+`}</p>
                                : <p className="block-data">{`${seperator(data?.criteria_range_from)} - ${seperator(data?.criteria_range_to)}`}</p>}
                          </div>
                      ) :null}
                      {data?.type === "sports" ? (
                          <>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Min Bet Amount</p>
                              <p className="block-data">{seperator(data?.min_bet_amount)}</p>
                            </div>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Roll Over</p>
                              <p className="block-data">{data?.rollover}</p>
                            </div>
                            {data?.max_odds <= 0 ? <div>
                                  <p className="block-title mb-2 text-ev-secondary">Min Odds</p>
                                  <p className="block-data">{data?.min_odds}+</p>
                                </div>
                                : <>
                                  <div>
                                    <p className="block-title mb-2 text-ev-secondary">Min Odds</p>
                                    <p className="block-data">{data?.min_odds}</p>
                                  </div>
                                  <div>
                                    <p className="block-title mb-2 text-ev-secondary">Max Odds</p>
                                    <p className="block-data">{data?.max_odds}</p>
                                  </div>
                                </>
                            }
                          </>
                      ) :null}
                      {data?.type === "casino" ? (
                          <>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Min Bet Amount</p>
                              <p className="block-data">{seperator(data?.min_bet_amount)}</p>
                            </div>
                            <div>
                              <p className="block-title mb-2 text-ev-secondary">Roll Over</p>
                              <p className="block-data">{data?.rollover}</p>
                            </div>
                          </>
                      ) :null}
                    </>
                ) : <div>
                  <p className="block-title mb-2 text-ev-secondary">Criteria</p>
                  {data?.payment_method ?
                      <p className="block-data capitalize">{data?.payment_method} Deposit</p>
                      : <p className="block-data">Not Available</p>}
                </div>}
                {data?.max_usage === -1 ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code Usage</p>
                  <p className="block-data">Unlimited</p>
                </div> : null}
                {data?.max_usage === -2 ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code Usage</p>
                  <p className="block-data">{ordinalToCardinal(data?.frequency_settings)} Deposit (Once)</p>
                </div> : null}
                {data?.max_usage >= 0 ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Promo Code Usage</p>
                  <p className="block-data">{data?.max_usage} <sup>times</sup> (Limited)</p>
                </div> : null}
                <div>
                  <p className="block-title mb-2 text-ev-secondary">In Promotion Page?</p>
                  {data?.in_promotion === 1 ?
                      <p className="block-data">Yes</p>
                      : <p className="block-data">No</p>}
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Priority</p>
                  <p className="block-data">{ data?.priority }</p>
                </div>
              </div>
              <div className="mt-2">
                <h4 className="text-lg font-semibold md:text-base text-ev-white">
                  Cashback Insights
                </h4>
              </div>
              <div className="grid grid-cols-3 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Cashback Category</p>
                  <p className="block-data capitalize">{data?.bonus_type}</p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Cashback Amount</p>
                  <p className="block-data">{seperator(data?.bonus_amount)}</p>
                </div>
                { data?.bonus_type === 'percentage' ? <div>
                  <p className="block-title mb-2 text-ev-secondary">Cashback Maximum Amount</p>
                  <p className="block-data">{seperator(data?.bonus_cap_amount)}</p>
                </div> : null}
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Validity Days</p>
                  <p className="block-data">{data?.validity_days}</p>
                </div>
              </div>
              <div className="mt-2">
                <h4 className="text-lg font-semibold md:text-base text-ev-white">
                  Other Details
                </h4>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Description</p>
                  <p className="block-data whitespace-pre">{data?.description || "-"}</p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Rules</p>
                  <p
                      className="block-data whitespace-pre"
                      dangerouslySetInnerHTML={{ __html: data?.rules || "-" }}
                  ></p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Terms & Conditions</p>
                  <p
                      className="block-data whitespace-pre"
                      dangerouslySetInnerHTML={{ __html: data?.t_c || "-" }}
                  ></p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Banner Image</p>
                  <img src={data?.image_url} alt="banner" />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 py-2">
                <div>
                  <p className="block-title mb-2 text-ev-secondary">Start Date</p>
                  <p className="block-data">{data?.start_date || "-"}</p>
                  <p className="block-data-small">
                    {data?.start_date_readable
                        ? `(${data.start_date_readable})`
                        : null}
                  </p>
                </div>
                <div>
                  <p className="block-title mb-2 text-ev-secondary">End Date</p>
                  <p className="block-data">{data?.end_date || "-"}</p>
                  <p className="block-data-small">
                    {data?.end_date_readable
                        ? `(${data.end_date_readable})`
                        : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
  );
};

export default CashbackModal;
