import { useEffect, useState, Fragment, useRef } from "react";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import {Button, ControlledInput, Input, Select, Table, TableRow} from "../../ui";

// PROJECT IMPORT
import {dateFormatter, responseToaster} from "../../helperFunctions";
import { useGetSupportlogMutation } from "./supportlogApi";
import { useSupportlogData } from "./supportlogSlice";
import { FILTER } from "../../constant";
import { useGetActiveDomainListQuery } from "../cashback/cashbackApi";
import DeleteBonusModal from "../bonus/DeleteBonusModal";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import SupportLogModal from "./SupportLogModal";
import {useGetMasterListQuery} from "../master/masterApi";
import {useMasterData} from "../master/masterSlice";

const initialValues = {
  domainId: "All",
};

const masterActionPayload: any = {
  action: 'support_logs'
};

const SupportLog = () => {
  const supportlogData: any = useSupportlogData();
  const [filter, setFilter] = useState(FILTER);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [deleteData, setDeleteData] = useState({});
  const [tableData, setTableData] = useState<any>([]);

  const [getSupportlog, { isLoading }] = useGetSupportlogMutation();
  const [showDiv, setShowDiv] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const isInitialMount = useRef(true);
  const [isSupportLogModalOpen, setIsSupportLogModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });
  useGetMasterListQuery(masterActionPayload, {refetchOnMountOrArgChange: true});
  const masterData: any = useMasterData();
  const prepareActionOptions: any = masterData.data?.action ?? [];
  const prepareSubActionOptions: any = masterData.data?.sub_action ?? [];
  // const onUpdateStatus = async (payload: any) => {
  //   try {
  //     setIsLoading(true);
  //     const res: any = await updateStatus(payload).unwrap();
  //     if (res) {
  //       setIsLoading(false);
  //       await onGetData();
  //     }
  //   } catch (err) {
  //     setIsLoading(false);

  //     console.error(err);
  //   }
  // };
  // const onDeleteStatus = async (payload: any) => {
  //   try {
  //     const res: any = await deleteData({ id: payload }).unwrap();
  //     showToaster(res?.message);
  //     await onGetData();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  useEffect(() => {
    setTableData(supportlogData?.data?.length ? [...supportlogData?.data] : []);
  }, [supportlogData]);
  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "User Name",
      name: "username",
    },
    {
      title: "Domain ID",
      name: "domain_id",
    },
    {
      title: "Received On",
      name: "created_at",
      Cell: (item: any) => {
        return (
            <span>
            {dateFormatter(item?.created_at)}
              <br />
            <small>( {item?.created_at_readable} )</small>
          </span>
        );
      },
    },
    {
      title: "Client IP",
      name: "client_ip",
    },
    {
      title: "Action",
      name: "action",
      Cell: (item: any) => {
        return (
            <span>
            {item?.action}
             <br/>
            <small>( {item?.action_title} )</small>
          </span>
        );
      },
    },
    {
      title: "Details",
      name: "action_details",
      Cell: (item: any) => {
        if (item.is_json) {
          return (
              <span
                  className="font-semibold underline cursor-pointer"
                  onClick={() => {
                    setIsSupportLogModalOpen(true);
                    setCurrentData(item.action_details);
                  }}
              >
              Click Here
              </span>
          );
        } else {
          return (
              <span>{item?.action_details}</span>
          );
        }
      },
    },
    {
      title: "Browser",
      name: "browser",
      Cell: (item: any) => {
        return (
            <span className="whitespace-pre-wrap">{item?.browser}</span>
        );
      },
    },
  ];

  const { register, handleSubmit, setValue, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onGetData = async () => {
    try {
      const values = getValues();
      const payload: any = {
        ...filter,
        filter_data: {
          userName: values?.userName,
          referenceId: values?.referenceId,
          searchData: searchTerm,
          action: values?.action,
          action_title: values?.action_title,
        },
      };
      delete payload["isFilter"];
      const res: any = await getSupportlog(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
        reset();
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-centere justify-between pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" /> Apply Filter
            </Button>

            {/* <Export
              variant="Transaparent"
              filename="download.csv"
              data={
                supportlogData?.data?.map((item: any) => ({
                  ...item,
                  status: item?.status ? "Active" : "Deactive",
                  created_at: dateFormatter(item?.created_at),
                  expiry_date: dateFormatter(item?.expiry_date),
                  bonus_amount: seperator(item?.bonus_amount),
                })) || []
              }
              headers={
                columns?.map((val) => ({
                  label: val?.title,
                  key: val?.name,
                })) || []
              }
            >
              Export
            </Export> */}
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                name="userName"
                label="Username"
                placeholder="Enter Username"
                register={register}
              />
              <Input
                name="referenceId"
                label="Reference Id"
                placeholder="Enter ReferenceId"
                register={register}
              />
              <Select
                  name="action"
                  label="Action"
                  options={prepareActionOptions}
                  register={register}
              />
              <Select
                  name="action_title"
                  label="Operation"
                  options={prepareSubActionOptions}
                  register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div>
          {/* <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
              {summaryData?.map((item: any, index) => {
                return (
                  <Fragment key={index}>
                    <Card
                      length={summaryData.length}
                      index={index}
                      value={bonusData?.summary?.[item.key]}
                      text={item?.text}
                      icon={item?.imgSrc}
                      rupee={item?.rupee}
                    />
                  </Fragment>
                );
              })}
            </div> */}
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={supportlogData?.data}
              isExpendable={false}
              setData={setTableData}
              count={supportlogData?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <DeleteBonusModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        tobeDeleteData={deleteData}
      />
      <SupportLogModal
          isOpen={isSupportLogModalOpen}
          data={currentData}
          onClose={() => {
            setIsSupportLogModalOpen(false);
            setCurrentData({});
          }}
      />
    </>
  );
};

export default SupportLog;
