import { useEffect, useState, Fragment, useRef } from "react";
import {MdEdit, MdRemoveRedEye} from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledInput,
  Export,
  getStatusTypes,
  Icon,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../helperFunctions";
import { useGetBonusMutation } from "./bonusApi";
import { setCurrentBonus, useBonusData } from "./bonusSlice";
import {DEFAULT_ORDER, FILTER} from "../../constant";
import { useDomainIdData } from "../cashback/cashbackSlice";
import { useGetActiveDomainListQuery } from "../cashback/cashbackApi";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import DeleteBonusModal from "./DeleteBonusModal";
import BonusModal from "./BonusModal";
import { useUser } from "../auth/authSlice";

const initialValues = {
  domainId: "All",
};

const statusOptions = [
  {
    value: 1,
    label: "Active",
    icon: <FaCheck />,
  },
  {
    value: 0,
    label: "Deactive",
    icon: <RxCross2 />,
  },
];

const Bonus = () => {
  const userData: any = useUser();

  const bonusData = useBonusData();
  const domainIdData = useDomainIdData();
  // const [statusManualLoading, setStatusManualLoading] = useState<any>(false);
  const [filter, setFilter] = useState(FILTER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER);
  const [resetOrderBy, setResetOrderBy] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [deleteData, setDeleteData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [getBonus, { isLoading }] = useGetBonusMutation();
  const [showDiv, setShowDiv] = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const isInitialMount = useRef(true);
  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const statusOption = [
  //   { value: 1, label: "Active", activeClassName: "green" },
  //   { value: 0, label: "Deactive", activeClassName: "red" },
  // ];

  // const onUpdateStatus = async (payload: any) => {
  //   try {
  //     setIsLoading(true);
  //     const res: any = await updateStatus(payload).unwrap();
  //     if (res) {
  //       setIsLoading(false);
  //       await onGetData();
  //     }
  //   } catch (err) {
  //     setIsLoading(false);

  //     console.error(err);
  //   }
  // };
  // const onDeleteStatus = async (payload: any) => {
  //   try {
  //     const res: any = await deleteData({ id: payload }).unwrap();
  //     showToaster(res?.message);
  //     await onGetData();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const onUpdateStatusManual = async (payload: any) => {
  //   try {
  //     setStatusManualLoading(payload?.id);

  //     const res: any = await updateStatus(payload).unwrap();

  //     const index = bonusData?.data?.findIndex(
  //       (item: any) => item?.id === payload?.id
  //     );
  //     if (index > -1) {
  //       const newData = [...bonusData.data];
  //       newData[index] = {
  //         ...newData[index],
  //         status: payload?.status,
  //       };
  //       dispatch(setBonusStatus({ ...bonusData, data: newData }));
  //     }
  //     responseToaster(res);

  //     setStatusManualLoading(false);
  //   } catch (err) {
  //     setStatusManualLoading(false);
  //   }
  // };

  const handleResetOrderBy = () => {
    setResetOrderBy(true); // Trigger reset
    setTimeout(() => setResetOrderBy(false), 0); // Reset the flag to allow future resets
  };

  const route = useLocation()?.pathname;

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "Priority",
      name: "priority",
      sortable: true,
      sortableKey: "priority",
    },
    {
      title: "Name",
      name: "name",
      sortable: false,
      sortableKey: "name",
    },
    {
      title: "Type",
      name: "type",
      sortable: false,
      sortableKey: "type",
      Cell: (item: any) => {
        return <span className="capitalize">{item?.type}</span>;
      },
    },
    {
      title: "Promo Code",
      name: "promo_code",
      sortable: false,
      sortableKey: "promo_code",
    },
    {
      title: "Bonus Amt/Per",
      name: "bonus_amount",
      sortable: false,
      sortableKey: "bonus_amount",
      Cell: (item: any) => {
        return (
          <span>
            {item?.bonus_type === "percentage"
              ? `${item?.bonus_amount}%`
              : seperator(item?.bonus_amount)}
          </span>
        );
      },
    },
    // {
    //   title: "Date",
    //   name: "start_date",
    //   Cell: (item: any) => {
    //     return (
    //       <>
    //         <span><b>Date : </b>{item?.start_date?.slice(0, 11)}</span>
    //         <p><b>Time : </b>{item?.start_date?.slice(11)}</p>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Start Date",
      name: "start_date",
      sortable: false,
      type: "date",
      sortableKey: "start_date",
      Cell: (item: any) => {
        return (
          <span>
            {dateFormatter(item?.start_date)}
            <br />
            <small>( {item?.start_date_readable} )</small>
          </span>
        );
      },
    },
    // {
    //   title: "End Date",
    //   name: "end_date",
    //   sortable: true,
    //   type: "date",
    //   sortableKey: "end_date",
    //   Cell: (item: any) => {
    //     return (
    //         <span>
    //           {dateFormatter(item?.end_date)}
    //           <br/>
    //           <small>( {item?.end_date_readable} )</small>
    //         </span>
    //     );
    //   },
    // },
    {
      title: "End Date",
      name: "end_date",
      sortable: false,
      type: "date",
      sortableKey: "end_date",
      Cell: (item: any) => {
        return (
          <span>
            {dateFormatter(item?.end_date)}
            <br />
            <small>( {item?.end_date_readable} )</small>
          </span>
        );
      },
    },
    // {
    //   title: "Last Updated",
    //   name: "updated_at",
    //   Cell: (item: any) => {
    //     return (
    //         <span>
    //           {dateFormatter(item?.updated_at)}
    //           <br/>
    //           <small>( {item?.updated_at_readable} )</small>
    //         </span>
    //     );
    //   },
    // },
    {
      title: "Created",
      name: "created_at",
      Cell: (item: any) => {
        return (
          <span>
            {dateFormatter(item?.created_at)}
            <br />
            <small>( {item?.created_at_readable} )</small>
          </span>
        );
      },
    },
    {
      title: "Status",
      name: "status",
      // sortable: true,
      // sortableKey: "status",
      Cell: (data: any) => {
        return (
          <div>
            {/* <ToggleSwitch
              isLoading={isStatusLoading === data?.domain_id}
              value={data?.status ? 1 : 0}
              options={statusOption}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    status: value,
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }}
            /> */}
            {/*<Switch*/}
            {/*  checked={!!data?.status}*/}
            {/*  isLoading={statusManualLoading === data?.id}*/}
            {/*  onChange={async (value: any, e: any) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    try {*/}
            {/*      const payload = {*/}
            {/*        status: value ? 1 : 0,*/}
            {/*        id: data?.id,*/}
            {/*      };*/}
            {/*      await onUpdateStatusManual(payload);*/}
            {/*    } catch (err) {}*/}
            {/*  }}*/}
            {/*/>*/}
            <span className={`${getStatusTypes(data?.status)}`}>
              {data?.status ? "Active" : "Deactive"}
            </span>
          </div>
        );
      },
    },
  ];

  if (
    userData?.access_level?.CAMPAIGN_VIEW ||
    userData?.access_level?.CAMPAIGN_UPDATE ||
    userData?.access_level?.CAMPAIGN_DELETE
  ) {
    const newCol: any = {
      title: "Actions",
      name: "actions",
      export: false,
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {userData?.access_level?.CAMPAIGN_VIEW ? (
                <Button
                    className="!h-7 w-8 !p-0"
                    variant="Transaparent"
                    onClick={() => {
                      setIsBonusModalOpen(true);
                      setCurrentData(item);
                    }}
                >
                  <MdRemoveRedEye />
                </Button>
            ) : null}
            {userData?.access_level?.CAMPAIGN_UPDATE ? (
              <Button
                className="!h-7 w-8 !p-0"
                onClick={() => {
                  dispatch(setCurrentBonus(item));
                  navigate(`${route}/${item?.id}`);
                }}
              >
                <MdEdit />
              </Button>
            ) : null}
            {userData?.access_level?.CAMPAIGN_DELETE ? (
              <Button
                className="!h-7 w-8 !p-0"
                variant="Error"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
              >
                <MdDelete />
              </Button>
            ) : null}
          </div>
        );
      },
    };
    columns?.push(newCol);
  }

  const { register, setValue, handleSubmit, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onGetData = async () => {
    try {
      const values = getValues();
      const payload: any = {
        ...filter,
        filter_data: {
          searchData: searchTerm,
          category: "bonus",
          domainId: values?.domainId,
          type: values?.type,
          status: values?.status,
          createdStartDate: dateFormatter(
            values?.createdStartDate?.[0],
            "date"
          ),
          createdEndDate: dateFormatter(values?.createdStartDate?.[1], "date"),
          startDate: dateFormatter(values?.startDate?.[0], "date"),
          endDate: dateFormatter(values?.startDate?.[1], "date"),
          endStartDate: dateFormatter(values?.endDate?.[0], "date"),
          endEndDate: dateFormatter(values?.endDate?.[1], "date"),
          ...orderBy
        },
      };
      delete payload["isFilter"];
      const res: any = await getBonus(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter, orderBy]);

  useEffect(() => {
    setTableData(bonusData?.data?.length ? [...bonusData?.data] : []);
  }, [bonusData]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const prepareDomainOptions = () => {
    const options: any = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  const prepareCampaignOptions = () => {
    const options: any = bonusData?.type?.map((val: any) => {
      return {
        value: val?.type,
        label: val?.type,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-center justify-between  pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" />
            </Button>
            {userData?.access_level?.CAMPAIGN_ADD ? (
              <Button
                type="button"
                onClick={() => {
                  navigate(`${route}/create`);
                }}
              >
                Add Bonus
              </Button>
            ) : null}
            <Export
              variant="Transaparent"
              filename="download.csv"
              data={bonusData?.data || []}
              headers={
                columns
                  ?.filter((item: any) => item?.export !== false)
                  ?.map((val) => ({
                    label: val?.title,
                    key: val?.name,
                  })) || []
              }
            >
              Export
            </Export>
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="domainId"
                label="Domain"
                options={prepareDomainOptions()}
                register={register}
              />
              <Select
                name="type"
                label="Type"
                options={prepareCampaignOptions()}
                register={register}
              />
              <ControlledDatePicker
                inputClassName="!w-52"
                name="createdStartDate"
                label="Bonus Create Date"
                placeholder="Select Create Date"
                selectsRange
                maxDate={new Date()}
                control={control}
              />
              <ControlledDatePicker
                name="startDate"
                label="Bonus Start Date"
                placeholder="Select Bonus Start Date"
                selectsRange
                control={control}
              />
              <ControlledDatePicker
                name="endDate"
                label="Bonus End Date"
                placeholder="Select Bonus End Date"
                selectsRange
                control={control}
              />
              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOptions}
                register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  handleResetOrderBy();
                  setFilter({ ...FILTER, isFilter: true });
                  setOrderBy({...DEFAULT_ORDER});
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div>
          {/* <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4 mainbox">
            {summaryData?.map((item: any, index) => {
              return (
                <Fragment key={index}>
                  <Card
                    length={summaryData.length}
                    index={index}
                    value={bonusData?.summary?.[item.key]}
                    text={item?.text}
                    icon={item?.imgSrc}
                    rupee={item?.rupee}
                  />
                </Fragment>
              );
            })}
          </div> */}
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={bonusData?.data}
              setData={setTableData}
              isExpendable={false}
              count={bonusData?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
              onOrderByFilter={(key: any, sort: any) => {
                setFilter({ ...FILTER, isFilter: true });
                setOrderBy({order_by_type: key, order_by: sort});
              }}
              resetOrderBy={resetOrderBy}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <BonusModal
        isOpen={isBonusModalOpen}
        data={currentData}
        onClose={() => {
          setIsBonusModalOpen(false);
          setCurrentData({});
        }}
      />
      <DeleteBonusModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        tobeDeleteData={deleteData}
      />
    </>
  );
};

export default Bonus;
