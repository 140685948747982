import { useEffect, useState, Fragment } from "react";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import { Button, ControlledInput, Table, TableRow, Select } from "../../ui";

// PROJECT IMPORT
import {
  getAllOptions,
  responseToaster,
  seperator,
} from "../../helperFunctions";
import { setCurrentLoyalty, useLoyaltyData } from "./loyaltySlice";
import { FILTER } from "../../constant";
import { useAppDispatch } from "../../utills/hooks";
import { useGetLoyaltyMutation } from "./loyaltyApi";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import DeleteLoyaltyModal from "./DeleteLoyaltyModal";
import { useDomainIdData } from "../cashback/cashbackSlice";
import { useUser } from "../auth/authSlice";

const initialValues = {
  domain_id: "All",
};

const Loyalty = () => {
  const user = useUser();
  const domainIdData: any = useDomainIdData();
  const loyaltyData = useLoyaltyData();
  const route = useLocation()?.pathname;

  const [filter, setFilter] = useState<any>(FILTER);

  const [getLoyalty, { isLoading }] = useGetLoyaltyMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [deleteData, setDeleteData] = useState({});
  const [tableData, setTableData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [showDiv, setShowDiv] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTableData(loyaltyData?.data?.length ? [...loyaltyData?.data] : []);
  }, [loyaltyData]);

  const columns = [
    {
      title: "Domain Name",
      name: "domain_name",
      sortable: false,
      sortableKey: "domain_name",
    },
    {
      title: "Criteria",
      name: "",
      Cell: (item: any) => {
        return (
          <div className="flex flex-col items-start font-bold gap-1">
            <span>Deposit Criteria :</span>
            <span>
              <br />
            </span>
            <span>Stakes :</span>
            <span>
              <br />
            </span>
            <span>Losses Cashback :</span>
            <span>Birthday Bonus :</span>
            <span>VIP Managers :</span>
            <span>Withdrawal :</span>
            <span>Customer Support :</span>
            <span>Status :</span>
          </div>
        );
      },
    },
    {
      title: "Bronze",
      name: "bronze",
      Cell: (item: any) => {
        const res = item.bronze ?? null;
        return res ? (
          <div className="flex flex-col items-start gap-1">
            <span>
              {res?.min_deposit > 0
                ? seperator(res?.min_deposit) + " - "
                : "Upto "}
              {seperator(res?.max_deposit)}
            </span>
            <span>Weekly</span>
            <span>
              {res?.min_wagering > 0
                ? seperator(res?.min_wagering) + " - "
                : "Upto "}
              {seperator(res?.max_wagering)}
            </span>
            <span>Weekly</span>
            <span>{res?.losses_cashback}% Losses Cashback</span>
            <span>{seperator(res?.birthday_bonus)}</span>
            <span>{res?.vip_managers ? "VIP Managers" : "NA"}</span>
            <span>{getAllOptions("withdraw", res?.withdrawal)}</span>
            <span>{getAllOptions("support", res?.customer_support)}</span>
            <span
              className={
                res?.status
                  ? "text-[#15CD2B] font-bold"
                  : "text-[#FF4E58F5] font-bold"
              }
            >
              ( {res?.status ? "Active" : "Inactive"} )
            </span>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Silver",
      name: "silver",
      Cell: (item: any) => {
        const res = item.silver ?? null;
        return res ? (
          <div className="flex flex-col items-start gap-1">
            <span>
              {seperator(res?.min_deposit)} - {seperator(res?.max_deposit)}
            </span>
            <span>Weekly</span>
            <span>
              {seperator(res?.min_wagering)} - {seperator(res?.max_wagering)}
            </span>
            <span>Weekly</span>
            <span>{res?.losses_cashback}% Losses Cashback</span>
            <span>{seperator(res?.birthday_bonus)}</span>
            <span>{res?.vip_managers ? "VIP Managers" : "NA"}</span>
            <span>{getAllOptions("withdraw", res?.withdrawal)}</span>
            <span>{getAllOptions("support", res?.customer_support)}</span>
            <span
              className={
                res?.status
                  ? "text-[#15CD2B] font-bold"
                  : "text-[#FF4E58F5] font-bold"
              }
            >
              ( {res?.status ? "Active" : "Inactive"} )
            </span>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Gold",
      name: "gold",
      Cell: (item: any) => {
        const res = item.gold ?? null;
        return res ? (
          <div className="flex flex-col items-start gap-1">
            <span>
              {seperator(res?.min_deposit)} - {seperator(res?.max_deposit)}
            </span>
            <span>Weekly</span>
            <span>
              {seperator(res?.min_wagering)} - {seperator(res?.max_wagering)}
            </span>
            <span>Weekly</span>
            <span>{res?.losses_cashback}% Losses Cashback</span>
            <span>{seperator(res?.birthday_bonus)}</span>
            <span>{res?.vip_managers ? "VIP Managers" : "NA"}</span>
            <span>{getAllOptions("withdraw", res?.withdrawal)}</span>
            <span>{getAllOptions("support", res?.customer_support)}</span>
            <span
              className={
                res?.status
                  ? "text-[#15CD2B] font-bold"
                  : "text-[#FF4E58F5] font-bold"
              }
            >
              ( {res?.status ? "Active" : "Inactive"} )
            </span>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Platinum",
      name: "platinum",
      Cell: (item: any) => {
        const res = item.platinum ?? null;
        return res ? (
          <div className="flex flex-col items-start gap-1">
            <span>
              {seperator(res?.min_deposit)} - {seperator(res?.max_deposit)}
            </span>
            <span>Weekly</span>
            <span>
              {seperator(res?.min_wagering)} - {seperator(res?.max_wagering)}
            </span>
            <span>Weekly</span>
            <span>{res?.losses_cashback}% Losses Cashback</span>
            <span>{seperator(res?.birthday_bonus)}</span>
            <span>{res?.vip_managers ? "VIP Managers" : "NA"}</span>
            <span>{getAllOptions("withdraw", res?.withdrawal)}</span>
            <span>{getAllOptions("support", res?.customer_support)}</span>
            <span
              className={
                res?.status
                  ? "text-[#15CD2B] font-bold"
                  : "text-[#FF4E58F5] font-bold"
              }
            >
              ( {res?.status ? "Active" : "Inactive"} )
            </span>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Kohinur",
      name: "kohinur",
      Cell: (item: any) => {
        const res = item.kohinur ?? null;
        return res ? (
          <div className="flex flex-col items-start gap-1">
            <span>{seperator(res?.min_deposit)} +</span>
            <span>Weekly</span>
            <span>{seperator(res?.min_wagering)} +</span>
            <span>Weekly</span>
            <span>{res?.losses_cashback}% Losses Cashback</span>
            <span>{seperator(res?.birthday_bonus)}</span>
            <span>{res?.vip_managers ? "VIP Managers" : "NA"}</span>
            <span>{getAllOptions("withdraw", res?.withdrawal)}</span>
            <span>{getAllOptions("support", res?.customer_support)}</span>
            <span
              className={
                res?.status
                  ? "text-[#15CD2B] font-bold"
                  : "text-[#FF4E58F5] font-bold"
              }
            >
              ( {res?.status ? "Active" : "Inactive"} )
            </span>
          </div>
        ) : (
          "-"
        );
      },
    },
  ];

  if (
    user?.access_level?.LOYALTY_ADD_UPDATE ||
    user?.access_level?.LOYALTY_DELETE
  ) {
    const newCol: any = {
      title: "Actions",
      name: "actions",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {user?.access_level?.LOYALTY_ADD_UPDATE ? (
              <Button
                className="!h-7 w-8 !p-0"
                onClick={() => {
                  dispatch(setCurrentLoyalty(item));
                  navigate(`${route}/${item?.domain_id}`);
                }}
              >
                <MdEdit />
              </Button>
            ) : null}
            {user?.access_level?.LOYALTY_DELETE ? (
              <Button
                className="!h-7 w-8 !p-0"
                variant="Error"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
              >
                <MdDelete />
              </Button>
            ) : null}
          </div>
        );
      },
    };
    columns?.push(newCol);
  }
  const { register, handleSubmit, reset, getValues, control } = useForm<any>({
    defaultValues: {
      ...initialValues,
    },
  });

  const onGetData = async () => {
    try {
      const values = getValues();
      const domain_id = values?.domain_id;
      const { page_no, limit, isFilter, ...rest } = filter;
      const payload: any = {
        page_no,
        limit,
        filter_data: {
          domain_id: domain_id,
          ...rest,
        },
      };
      const res: any = await getLoyalty(payload).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  const prepareDomainOptions = () => {
    const options: any = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, ...values, isFilter: true });
  };

  const handleSearch = (e: { target: { value: any } }) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredResults = loyaltyData?.data?.filter(
      (item: { [s: string]: unknown } | ArrayLike<unknown>) => {
        return Object.values(item).some(
          (val) =>
            val && val.toString().toLowerCase().includes(term.toLowerCase())
        );
      }
    );

    setTableData(filteredResults);
  };

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-centere justify-between pb-3">
          <ControlledInput
            name=""
            className="w-100"
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={handleSearch}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" />
            </Button>
            {user?.access_level?.LOYALTY_ADD_UPDATE ? (
            <Button
              type="button"
              onClick={() => {
                navigate(`${route}/create`);
              }}
            >
              Add Loyalty
            </Button>
            ):null}
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                label="Domain"
                name="domain_id"
                options={prepareDomainOptions()}
                register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10"
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  setSearchTerm("");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div>
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={loyaltyData?.data}
              isExpendable={false}
              // count={loyaltyData?.total_item || 0}
              // pagination={filter}
              // handlePaginationChange={(pagination) => {
              //   setFilter({
              //     ...filter,
              //     ...pagination,
              //     isFilter: false,
              //   });
              // }}
              setData={setTableData}
              count={loyaltyData?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <DeleteLoyaltyModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        tobeDeleteData={deleteData}
      />
    </>
  );
};

export default Loyalty;
