import { Button, Loader } from "../../ui";

const ConfirmModal = (props: any) => {
  const {
    isOpen,
    isLoading,
    onHide,
    onDoneClick,
    text = "Are you sure you want this?",
    doneText = "Yes",
    cancelText = "No",
  } = props;

  const onClose = () => {
    onHide();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container bg-chatlook-primary border w-4/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12  md:w-7/12 sm:w-7/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-9 px-6 text-center ">
          <p className="text-lg text-ev-secondary">{text}</p>
          <div className="flex justify-center pt-4 items-center gap-3">
            {!isLoading ? (
              <Button onClick={onDoneClick}>{doneText}</Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
            <Button variant="Transaparent" onClick={onClose}>
              {cancelText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
