import React, {CSSProperties, useEffect, useState} from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import {
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiExpandUpDownLine,
} from "react-icons/ri";

interface TableHeaderProps {
  className?: string;
  style?: CSSProperties;
  isExpendable?: boolean;
  columns: any[];
  onSort: any;
  resetOrderBy?:any;
}
const TableHeader = (props: TableHeaderProps) => {
  const {
    className = "",
    style = {},
    columns = [],
    isExpendable = true,
    onSort,
    resetOrderBy,
  } = props;
  const [sortType, setSortType] = useState("asc");

  const [currentSort, setCurrentSort] = useState<any>({
    columnKey: null,
    order: null, // Can be 'asc' or 'desc'
  });

  const toggleSortType = (columnKey: any) => {
    if (currentSort.columnKey === columnKey) {
      return currentSort.order === "asc" ? "desc" : "asc";
    }
    return "asc";
  };

  const handleSort = (column: any) => {
    const newSortOrder = toggleSortType(column.sortableKey);
    setCurrentSort({
      columnKey: column.sortableKey,
      order: newSortOrder,
    });
    onSort(column.sortableKey, 'normal', newSortOrder);
  };

  useEffect(() => {
    if (resetOrderBy) {
      setCurrentSort({
        columnKey: null,
        order: null,
      });
      onSort(null, null, null);
    }
  }, [resetOrderBy, onSort]);

  return (
    <thead
    className={`${className} table-header sticky text-sm font-medium text-ev-white top-[-1px] bg-chatlook-primary h-[40px] z-10`}
      style={{ ...style }}
    >
      <tr>
        {isExpendable ? (
          <th className="no-wrap">
            <BiMenuAltLeft />
          </th>
        ) : null}
        {columns?.map((column, index) => {
          return (
            <th key={index} className="text-start no-wrap">
              <div className="flex flex-row items-center gap-3">
                <p>{column?.title}</p>
                {column?.sortable ? (
                  <div
                    onClick={() => handleSort(column)}
                    className="cursor-pointer"
                  >
                    {currentSort.columnKey === column.sortableKey ? (
                        currentSort.order === "asc" ? (
                            <RiArrowUpSLine fill="#9e9393" /> // Ascending icon
                        ) : (
                            <RiArrowDownSLine fill="#9e9393" /> // Descending icon
                        )
                    ) : (
                        <RiExpandUpDownLine fill="#9e9393" /> // Neutral icon
                    )}
                  </div>
                ) : null}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
