// THIRD - PARTY IMPORT
import psl from "psl";

export const LIMIT = 50;
export const FILTER = {
  page_no: 1,
  limit: LIMIT,
  isFilter: false,
};

// export const BASE_URL = `https://${window.location.hostname}`;

export const LOGOUT_TIME = 1000 * 60 * 30;

export const LOGOUT_EVENTS = [
  "mousedown",
  "mousemove",
  "wheel",
  "keydown",
  "touchstart",
  "scroll",
];

export const DOMAINS: any = psl.parse("merchant-new.devteamandro.co.in");
// export const DOMAINS:any = psl.parse(window.location.hostname)

export const Weeks:any = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const DEFAULT_ORDER = {
  order_by_type: 'id',
  order_by: 'desc',
};