import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledTimePicker,
  DropDown,
  Icon,
  Input,
  Loader,
  Select,
  Switch,
} from "../../ui";
import RadioButton from "../../ui/elements/RadioButton";

// PROJECT IMPORT
import {
  vipUserLevelOption,
  walletKeys,
  criteriaNameOptions,
  frequencyOptions,
  betCriteriaOptions,
  referCriteriaOptions,
  onlyLimitedfrequencyOptions, questionOptions,
  frequencyNumberDepositOptions
} from "./mock";
import { useCurrentBonus, usePlayerListOption } from "./customSlice";
import {
  useAddCustomMutation,
  useAddUpdateCustomMutation,
  useGetPlayerListMutation,
} from "./customApi";
import {
  dateFormatter,
  showToaster,
  isMoreThanZero,
  isNegativeZero,
  prepareObjTotal,
} from "../../helperFunctions";
import UploadCSV from "./UploadCSV";
import { useDomainIdData } from "../cashback/cashbackSlice";
import { useGetActiveDomainListQuery } from "../cashback/cashbackApi";

// THIRD - PARTY IMPORT
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoMdCloseCircle } from "react-icons/io";
import { MdFileUpload } from "react-icons/md";
import { Editor } from "primereact/editor";
import moment from "moment";
import * as yup from "yup";
import dayjs from "dayjs";
import {priorityOptions} from "../common/mock";

const typeOptions = [
  {
    value: "bonus",
    label: "Bonus",
  },
  {
    value: "cashback",
    label: "Cashback",
  },
];

const bonusTypeOptions = [
  {
    value: "flat",
    label: "Flat",
  },
  {
    value: "percentage",
    label: "Percentage",
  },
];

const campaignTypeOptions = [
  {
    value: "deposit",
    label: "Deposit",
  },
  {
    value: "sports",
    label: "Sports",
  },
  {
    value: "casino",
    label: "Casino",
  },
  {
    value: "refer",
    label: "Refer",
  },
];

const campaignSubTypeOptions = [
  {
    value: "next",
    label: "Next",
  },
  {
    value: "number",
    label: "Number",
  },
];

const customOptions = [
  {
    value: "players",
    label: "Players",
  },
  {
    value: "vipLevels",
    label: "VIP Levels",
  },
];

const CustomForm = () => {
  const [getPlayerList] = useGetPlayerListMutation();
  const domainIdData: any = useDomainIdData();
  const currentBonus = useCurrentBonus();
  const navigate = useNavigate();
  const { id } = useParams();
  const playerListOption = usePlayerListOption();
  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [createBonus] = useAddCustomMutation();
  const [updateBonus] = useAddUpdateCustomMutation();
  const [bonusType, setBonusTypeOption] = useState<string>("");
  const [percentage, setPercentage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [imageData, setImageData] = useState<any>({
    width: 0,
    height: 0,
    size: 0,
  });
  const [isUploadCSVModalOpen, setIsUploadCSVModalOpen] = useState<any>(false);

  const onGetPlayerList = async () => {
    try {
      const domainId = getValues()?.domainId || "";
      const payload: any = {
        filter_data: {
          domain_id: domainId,
        },
      };
      await getPlayerList(payload);
    } catch (err) {
    } finally {
      setValue("playerId", []);
    }
  };

  const [percentages, setPercentages] = useState<any>({
    sports_wallet: "",
    casino_wallet: "",
    // main_wallet: "",
  });
  const [totalWallet, setTotalWallet] = useState(0);

  // const calculateSum = () => {
  //   const percentages1 = Object.values(percentages).map((percentage: any) =>
  //     parseFloat(percentage)
  //   );

  //   const validPercentages = percentages1.filter(
  //     (value: number) => !isNaN(value)
  //   );

  //   const sum = validPercentages.reduce(
  //     (total: any, value: any) => total + value,
  //     0
  //   );

  //   return sum;
  // };

  useEffect(() => {
    if (id) {
      // onGetPlayerList().then(()=>{
      //   setValue(
      //       "playerId",
      //       playerListOption?.player_list
      //           ?.filter((item: any) =>
      //               Object.values(JSON.parse(currentBonus?.player_id)).includes(
      //                   item?.player_id
      //               )
      //           )
      //           .map((item: any) => item?.player_id)
      //   );
      // })
    }
  }, [id]);

  // useGetPlayerListQuery(undefined, {
  //   refetchOnFocus: true,
  // });

  const calculateSum = () => {
    const percentages1 = Object.values(percentages).map((percentage: any) =>
        parseFloat(percentage)
    );

    const validPercentages = percentages1.filter(
        (value: number) => !isNaN(value)
    );

    const sum = validPercentages.reduce(
        (total: any, value: any) => total + value,
        0
    );

    return sum;
  };

  const schema = yup.object({
    validityDays: yup
        .string()
        .when("campaignSubCategory", {
          is: (value: any) => {
            return value === "bonus";
          },
          then: () => yup.number().transform((value) => (Number.isNaN(value) ? 0 : value)).min(1, "At least [1] validity days required"),
          otherwise: (yup) => yup.notRequired().optional(),
        }),
    // maxBonusConversionSports: yup
    //   .mixed()
    //   .when(["sports_wallet", "campaignSubCategory"], {
    //     is: (value: any, value2: string) => {
    //       if (value2 === "bonus") return value > 0;
    //       return false;
    //     },
    //     then: () => yup.number().required("Please enter a max bonus converted"),
    //     otherwise: (yup) => yup.notRequired().optional().nullable(),
    //   }),
    minBetAmountSports: yup
      .mixed()
      .when(["sports_wallet", "campaignSubCategory"], {
        is: (value: number, value2: string) => {
          if (value2 === "bonus") return value > 0;
          return false;
        },
        then: () => yup.number().required("Please enter a bet amount"),
        otherwise: (yup) => yup.notRequired().optional().nullable(),
      }),
    rollOverSports: yup.mixed().when(["sports_wallet", "campaignSubCategory"], {
      is: (value: number, value2: string) => {
        if (value2 === "bonus") return value > 0;
        return false;
      },
      then: () => yup.number().required("Please enter a rollover"),
      otherwise: (yup) => yup.notRequired().optional().nullable(),
    }),
    // wageringContributionSports: yup.mixed().when("sports_wallet", {
    //   is: (value: number) => {
    //     return value > 0
    //   },
    //   then: () => yup.number().required('Please enter a wagering contribution'),
    //   otherwise: (yup) => yup.notRequired().optional().nullable(),
    // }),
    // maxBonusConversionCasino: yup
    //   .mixed()
    //   .when(["casino_wallet", "campaignSubCategory"], {
    //     is: (value: number, value2: string) => {
    //       if (value2 === "bonus") return value > 0;
    //       return false;
    //     },
    //     then: () => yup.number().required("Please enter a max bonus converted"),
    //     otherwise: (yup) => yup.notRequired().optional().nullable(),
    //   }),
    minBetAmountCasino: yup
      .mixed()
      .when(["casino_wallet", "campaignSubCategory"], {
        is: (value: number, value2: string) => {
          if (value2 === "bonus") return value > 0;
          return false;
        },
        then: () => yup.number().required("Please enter a bet amount"),
        otherwise: (yup) => yup.notRequired().optional().nullable(),
      }),
    rollOverCasino: yup.mixed().when(["casino_wallet", "campaignSubCategory"], {
      is: (value: number, value2: string) => {
        if (value2 === "bonus") return value > 0;
        return false;
      },
      then: () => yup.number().required("Please enter a rollover"),
      otherwise: (yup) => yup.notRequired().optional().nullable(),
    }),
    campaignSubCategory: yup.string().required("Select a main type"),
    maxUsage: yup.number().when("promoFrequency", {
      is: (value: string) => {
        return value === "limited";
      },
      then: (yup) => yup.min(1, "At least [1] promo code usage required"),
      otherwise: (yup) => yup.notRequired().optional().nullable(),
    }),
    startDate: yup.mixed().required("Please enter a start date"),
    endDate: yup.string().required("Please enter a end date"),
    startTime: yup.string().required("Please enter a start time"),
    endTime: yup
      .date()
      .when(
        ["startDate", "endDate", "startTime"],
        ([startDate, endDate, startTime], schema) => {
          const startDateTime = moment(startDate);
          const endDateTime = moment(endDate);
          const startTimeMoment = moment(startTime);

          startDateTime.set({
            hour: startTimeMoment.hours(),
            minute: startTimeMoment.minutes(),
            second: startTimeMoment.seconds(),
          });

          if (
            startDateTime.isSame(endDateTime, "day") &&
            startDateTime.isSameOrAfter(endDateTime)
          ) {
            return schema.min(
              startDateTime.toDate(),
              "End time must be after start time"
            );
          }

          return schema;
        }
      )
      .required("Please enter a end time"),
    all_wallet: yup
        .mixed()
        .when(["campaignSubCategory"], {
          is: (value: string) => {
            if (value === "bonus") return true;
            return false;
          },
          then: () => yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .test("sum", "The total should equal 100", function () {
                return calculateSum() === 100;
              })
              .notRequired(),
          otherwise: (yup) => yup.notRequired().optional().nullable(),
        }),
    promoCode: yup.string().required("Please enter a promo code"),
    image: yup
      .mixed()
      .test(
        "image",
        "File size exceeds the maximum limit (5MB)",
        (value: any) => {
          if (!value) {
            return true;
          }
          return value.size <= 5 * 1024 * 1024;
        }
      ),
  });

  const handleInputChange = (e: any) => {
    const inputValue: any = e.target.value;
    if (
      inputValue === "" ||
      inputValue === "%" ||
      (percentage.length > inputValue.length && inputValue.length === 1)
    ) {
      setPercentage("");
      setValue("bonusAmount", 0);
    } else if (!inputValue.includes("%") && inputValue.length > 1) {
      const newValue: any = `${inputValue.slice(0, -1)}%`;
      setPercentage(newValue);
      setValue("bonusAmount", +inputValue.slice(0, -1));
    } else {
      const sanitizedValue: any = inputValue.replace("%", "");
      if ((+sanitizedValue || 0) >= 0) {
        const newValue =
          sanitizedValue + (sanitizedValue.includes("%") ? "" : "%");
        setPercentage(newValue);
        setValue("bonusAmount", +newValue.slice(0, -1));
      }
    }
  };

  const isDisabled = !!(new Date(currentBonus?.start_date) < new Date() && id);
  // const isValidityExpired = !!(
  //   new Date(currentBonus?.expiry_date) < new Date() && id
  // );
  const isCampaignExpired = !!(
    new Date(currentBonus?.end_date) < new Date() && id
  );
  const prepareDefaultValues = () => {
    if (id) {
      const sports_conversion = JSON.parse(
        currentBonus?.sports_conversion || "{}"
      );
      const casino_conversion = JSON.parse(
        currentBonus?.casino_conversion || "{}"
      );
      const game_contributions = JSON.parse(
        currentBonus?.game_contributions || "{}"
      );
      const clonedPer = JSON.parse(currentBonus?.wallet_per);
      const clonedPer2 = JSON.parse(currentBonus?.is_previous_forfeited);
      return {
        campaignName: currentBonus?.name,
        campaignType:
          currentBonus?.type === currentBonus?.sub_category
            ? ""
            : currentBonus?.type,
        campaignSubCategory: currentBonus?.sub_category,
        campaignSubType: currentBonus?.sub_type,
        promoCode: currentBonus?.promo_code,
        priority: currentBonus?.priority,
        bonusType: currentBonus?.bonus_type,
        bonusAmount: currentBonus?.bonus_amount,
        minBetAmount: currentBonus?.min_bet_amount,
        status: currentBonus?.status,
        inPromotion: currentBonus?.in_promotion,
        isOnlyNewPlayer: currentBonus?.is_only_new_player,
        description: currentBonus?.description || "",
        frequencySettings: currentBonus?.frequency_settings,
        bonusCapAmount: currentBonus?.bonus_cap_amount,
        rollOver: currentBonus?.rollover || 0,
        maxUsage: currentBonus?.max_usage,
        promoFrequency: currentBonus?.promo_frequency,
        minOdds: currentBonus?.min_odds,
        maxOdds: currentBonus?.max_odds,
        wageringContribution: currentBonus?.wagering_contribution || "",
        rules: currentBonus?.rules || "",
        tC: currentBonus?.t_c || "",
        minDeposit: currentBonus?.min_deposit,
        maxDeposit: currentBonus?.max_deposit,
        criteriaName: currentBonus?.criteria_name,
        criteriaRangeFrom: currentBonus?.criteria_range_from,
        criteriaRangeTo: currentBonus?.criteria_range_to,
        image: imageData?.size,
        liveCasino: game_contributions?.live_casino,
        slot: game_contributions?.slot,
        tableGames: game_contributions?.table_games,
        virtualGames: game_contributions?.virtual_games,
        // days: currentBonus?.days,
        domainId: currentBonus?.domain_id ? currentBonus?.domain_id : "",
        playerId: currentBonus?.player_id ? currentBonus?.player_id : [],
        vipUserLevel: currentBonus?.vip_user_level
          ? currentBonus?.vip_user_level
          : [],
        customOption: currentBonus?.vip_user_level ? "vipLevels" : "players",
        // userLevel: currentBonus?.user_level,
        startDate: new Date(currentBonus?.start_date),
        startTime: dayjs(
          currentBonus?.start_date?.slice(11) || "00:00:00",
          "HH:mm:ss"
        ),
        endDate: new Date(currentBonus?.end_date),
        endTime: dayjs(
          currentBonus?.end_date?.slice(11) || "00:00:00",
          "HH:mm:ss"
        ),
        // expiryDate: new Date(currentBonus?.expiry_date),
        // expiryTime: dayjs(
        //   currentBonus?.expiry_date?.slice(11) || "00:00:00",
        //   "HH:mm:ss"
        // ),
        validityDays: currentBonus?.validity_days,
        // dateType: "expiryDate",
        sports_wallet_previous_forfeited: clonedPer2?.sports || 1,
        casino_wallet_previous_forfeited: clonedPer2?.casino || 1,
        sports_wallet: clonedPer?.sports || 0,
        casino_wallet: clonedPer?.casino || 0,
        // main_wallet: clonedPer?.main || 0,
        // maxBonusConversionSports: sports_conversion?.max_converted,
        maxBonusConversionSports: 0,
        minBetAmountSports: sports_conversion?.min_bet,
        rollOverSports: sports_conversion?.rollover,
        minOddsSports: sports_conversion?.min_odds,
        maxOddsSports: sports_conversion?.max_odds,
        wageringContributionSports: sports_conversion?.wagering_contribution,
        // maxBonusConversionCasino: casino_conversion?.max_converted,
        maxBonusConversionCasino: 0,
        minBetAmountCasino: casino_conversion?.min_bet,
        rollOverCasino: casino_conversion?.rollover,
        liveCasinoCasino: casino_conversion?.live_casino,
        slotCasino: casino_conversion?.slot,
        tableGamesCasino: casino_conversion?.table_games,
        virtualGamesCasino: casino_conversion?.virtual_games,
      };
    } else {
      return {
        domainId: "",
        playerId: [],
        vipUserLevel: [],
        startTime: null,
        endTime: null,
        // expiryTime: null,
        // dateType: "expiryDate",
        maxUsage: 1,
        bonusType: "flat",
        inPromotion: 0,
        sports_wallet_previous_forfeited: 1,
        casino_wallet_previous_forfeited: 1,
      };
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    watch,
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });

  const onSubmit = async (values: any) => {
    // if (imageData?.width !== 530 || imageData?.height !== 237) {
    //   setError("image", {
    //     message: "File resolution should be width 530px * height 237px",
    //   });
    //   return;
    // }
    let formData = new FormData();
    formData.append("campaignName", values?.campaignName || "");
    formData.append("campaignType", values?.campaignType || "");
    formData.append("campaignSubCategory", values?.campaignSubCategory || "");
    formData.append("campaignSubType", values?.campaignSubType || "");
    formData.append("promoCode", values?.promoCode || "");
    formData.append("priority", values?.priority || 100);
    formData.append("bonusType", values?.bonusType || "");
    formData.append("bonusAmount", values?.bonusAmount || "");
    formData.append("minBetAmount", values?.minBetAmount || 0);
    formData.append("status", "1");
    formData.append("inPromotion", values?.inPromotion || 0);
    formData.append("isOnlyNewPlayer", values?.isOnlyNewPlayer || 0);
    formData.append("description", values?.description || "");
    formData.append("frequencySettings", values?.frequencySettings || "");
    formData.append("bonusCapAmount", values?.bonusCapAmount || "");
    formData.append("rollOver", values?.rollOver || 0);
    formData.append(
      "maxUsage",
      values?.promoFrequency === "limited" ? values?.maxUsage : -1
    );
    formData.append("promoFrequency", values?.promoFrequency);
    formData.append("minOdds", values?.minOdds || 0);
    formData.append("maxOdds", values?.maxOdds || 0);
    formData.append("wageringContribution", values?.wageringContribution || 0);
    formData.append("rules", values?.rules || "");
    formData.append("tC", values?.tC || "");
    formData.append("minDeposit", values?.minDeposit || 0);
    formData.append("maxDeposit", values?.maxDeposit || 0);
    formData.append("criteriaName", values?.criteriaName || "");
    formData.append("criteriaRangeFrom", values?.criteriaRangeFrom || 0);
    formData.append("criteriaRangeTo", values?.criteriaRangeTo || 0);
    formData.append("image", values?.image || "");
    formData.append("liveCasino", values?.liveCasino || 0);
    formData.append("slot", values?.slot || 0);
    formData.append("tableGames", values?.tableGames || 0);
    formData.append("virtualGames", values?.virtualGames || 0);

    // formData.append("userLevel", values?.userLevel || "");
    // formData.append("days", values?.days || "");
    formData.append("imageHeight", imageData?.height || 0);
    formData.append("imageWidth", imageData?.width || 0);
    formData.append("imageSize", imageData?.size || 0);
    if (values.campaignSubCategory === 'bonus') {
      formData.append("validityDays", values?.validityDays || "");
    } else {
      formData.append("validityDays", "0");
    }
    formData.append("domainId", values?.domainId || "");
    formData.append("sports_wallet_previous_forfeited", values?.sports_wallet_previous_forfeited || 1);
    formData.append("casino_wallet_previous_forfeited", values?.casino_wallet_previous_forfeited || 1);
    formData.append(
      "sports_wallet",
      values?.sports_wallet || (totalWallet === 100 ? 0 : "")
    );
    formData.append(
      "casino_wallet",
      values?.casino_wallet || (totalWallet === 100 ? 0 : "")
    );
    // formData.append(
    //     "main_wallet",
    //     values?.main_wallet || (totalWallet === 100 ? 0 : "")
    // );

    if (values.customOption === "players") {
      values.vipUserLevel = [];
    } else {
      values.playerId = [];
    }

    formData.append(
      "playerId",
      values?.playerId?.length ? values?.playerId : ""
    );
    formData.append(
      "vipUserLevel",
      values?.vipUserLevel?.length ? values?.vipUserLevel : ""
    );

    // if (values?.expiryDate && values?.expiryTime) {
    //   const formateExpiryDate: any = values?.expiryDate
    //     ? dateFormatter(values?.expiryDate, "Default")
    //     : "";
    //   formData.append(
    //     "expiryDate",
    //     `${formateExpiryDate} ${
    //       dayjs(values?.expiryTime)?.format("HH:mm:ss") || "00:00:00"
    //     }`
    //   );
    // }

    const formateStartDate: any = values?.startDate
      ? dateFormatter(values?.startDate, "Default")
      : "";

    formData.append(
      "startDate",
      `${formateStartDate} ${
        moment(values?.startTime)?.format("HH:mm:ss") || "00:00:00"
      }`
    );

    const formateEndDate: any = values?.endDate
      ? dateFormatter(values?.endDate, "Default")
      : "";
    formData.append(
      "endDate",
      `${formateEndDate} ${
        moment(values?.endTime)?.format("HH:mm:ss") || "00:00:00"
      }`
    );
    // formData.append(
    //   "maxBonusConversionSports",
    //   values?.maxBonusConversionSports || 0
    // );
    formData.append("maxBonusConversionSports", "0");
    formData.append("minBetAmountSports", values?.minBetAmountSports || 0);
    formData.append("rollOverSports", values?.rollOverSports || 0);
    formData.append("minOddsSports", values?.minOddsSports || 0);
    formData.append("maxOddsSports", values?.maxOddsSports || 0);
    formData.append(
      "wageringContributionSports",
      values?.wageringContributionSports || 0
    );
    // formData.append(
    //   "maxBonusConversionCasino",
    //   values?.maxBonusConversionCasino || 0
    // );
    formData.append("maxBonusConversionCasino", "0");
    formData.append("minBetAmountCasino", values?.minBetAmountCasino || 0);
    formData.append("rollOverCasino", values?.rollOverCasino || 0);
    formData.append("liveCasinoCasino", values?.liveCasinoCasino || 0);
    formData.append("slotCasino", values?.slotCasino || 0);
    formData.append("tableGamesCasino", values?.tableGamesCasino || 0);
    formData.append("virtualGamesCasino", values?.virtualGamesCasino || 0);
    formData.append("category", "custom");
    // formData.append("isNotify", values?.is_notify || 0);
    // formData.append("remarks", values?.remarks || "");
    setLoading(true);
    if (id) {
      try {
        formData.append("id", currentBonus?.id);
        const res: any = await updateBonus(formData).unwrap();
        showToaster(res?.message);
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await createBonus(formData).unwrap();
        showToaster(res?.message);
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const BonusTypeChange = (value: string) => {
    setBonusTypeOption(value);
    setValue("bonusType", value);
  };

  const prepareDomainOptions = () => {
    const options = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options;
  };

  const preparePlayerOptions = () => {
    const options = playerListOption?.player_list?.map((val: any) => {
      return {
        value: val?.player_id,
        label: val?.username,
      };
    });
    return options;
  };

  const handleWalletInputChange = (key: string, value: any) => {
    if (
      value < 0 ||
      value > 100 ||
      isNegativeZero(value) ||
      isMoreThanZero(value)
    ) {
      return;
    }
    resetData("bonus_wallet_calc", key);
    // if (key === "sports_wallet") {
    //   resetField("maxBonusConversionSports");
    //   resetField("minBetAmountSports");
    //   resetField("rollOverSports");
    //   resetField("minOddsSports");
    //   resetField("maxOddsSports");
    //   resetField("wageringContributionSports");
    // } else {
    //   resetField("maxBonusConversionCasino");
    //   resetField("minBetAmountCasino");
    //   resetField("rollOverCasino");
    //   resetField("liveCasinoCasino");
    //   resetField("slotCasino");
    //   resetField("tableGamesCasino");
    //   resetField("virtualGamesCasino");
    // }
    setValue(key, value);
    setPercentages({
      ...percentages,
      [key]: value,
    });
    const newValues = {
      ...percentages,
      [key]: value,
    };
    const nullValueKeys: any = Object.keys(newValues).filter((key) => {
      return (
        newValues[key] === null || newValues[key] === 0 || newValues[key] === ""
      );
    });
    const total = prepareObjTotal({ ...newValues });

    if (total > 100) {
      setError("all_wallet", {
        message: "The total should equal 100",
      });
    } else if (total < 100) {
      setError("all_wallet", {
        message: "The total should equal 100",
      });
    } else {
      clearErrors("all_wallet", "");
    }

    setTotalWallet(total);
    if (
      total === 100 &&
      nullValueKeys?.length &&
      !nullValueKeys.includes(key)
    ) {
      const clonedPercentages = { ...newValues };

      nullValueKeys.forEach((key: string) => {
        clonedPercentages[key] = 0;
        setValue(key, "0");
      });
      setPercentages(clonedPercentages);
    } else {
      if (total !== 100 && nullValueKeys?.length) {
        const clonedPercentages = { ...newValues };
        nullValueKeys.forEach((key: string) => {
          clonedPercentages[key] = "";
          setValue(key, "");
        });
        setPercentages(clonedPercentages);
      }
    }
  };

  const handleWalletSelectChange = (key: string, value: any) => {
    setValue(key, value);
  };

  useEffect(() => {
    if (id) {
      setImage(currentBonus?.image_url);
      setBonusTypeOption(currentBonus?.bonus_type);

      setPercentage(
        currentBonus?.bonus_amount ? `${currentBonus?.bonus_amount}%` : ""
      );
      setTotalWallet(100);
      const values = JSON.parse(currentBonus?.wallet_per || "{}");
      setPercentages({
        sports_wallet: values?.sports || "",
        casino_wallet: values?.casino || "",
        // main_wallet: values?.main || "",
      });
    } else {
      setBonusTypeOption("flat");
    }
    if (currentBonus?.image_detail && id) {
      const clonedImageData = JSON.parse(currentBonus?.image_detail);
      setImageData({
        ...imageData,
        height: clonedImageData?.image_height,
        width: clonedImageData?.image_width,
        size: clonedImageData?.image_size,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBonus, id]);

  const closeImage = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    clearErrors("image", "");
    setValue("image", "");
    setImageData({ width: 0, height: 0, size: 0 });
    setImage("");
  };

  const resetData = (action: any, type: any) => {
    if (action === "bonus_wallet_calc") {
      // setValue("maxBonusConversionSports", null);
      setValue("minBetAmountSports", null);
      setValue("rollOverSports", null);
      setValue("minOddsSports", null);
      setValue("maxOddsSports", null);
      setValue("wageringContributionSports", null);
      // setValue("maxBonusConversionCasino", null);
      setValue("minBetAmountCasino", null);
      setValue("rollOverCasino", null);
      setValue("liveCasinoCasino", null);
      setValue("slotCasino", null);
      setValue("tableGamesCasino", null);
      setValue("virtualGamesCasino", null);
    } else if (action === "custom_type" || action === "custom_main_type") {
      setTotalWallet(0);
      setPercentages({
        sports_wallet: 0,
        casino_wallet: 0,
      });
      setValue("campaignSubType", null);
      setValue("minDeposit", null);
      setValue("maxDeposit", null);
      setValue("minBetAmount", 0);
      setValue("frequencySettings", null);
      setValue("rollOver", 0);
      setValue("maxUsage", 1);
      setValue("promoFrequency", "limited");
      setValue("minOdds", null);
      setValue("maxOdds", null);
      setValue("criteriaName", null);
      setValue("criteriaRangeFrom", null);
      setValue("criteriaRangeTo", null);
      setBonusTypeOption("");
      setPercentage("");
      setValue("bonusType", "");
      setValue("bonusAmount", 0);
      setValue("bonusCapAmount", 0);
      setValue("validityDays", "");
      // setValue("paymentMethod", null);
    } else if (action === 'reset_frequency') {
      setValue("promoFrequency", "limited");
      setValue("maxUsage", 1);
    }
   if (type === 'criteria_deposit') {
      setValue("criteriaRangeFrom", null);
      setValue("criteriaRangeTo", null);
    } else if (type === 'criteria_sports_casino') {
      setValue("minBetAmount", null);
      setValue("rollOver", null);
      setValue("minOdds", null);
      setValue("maxOdds", null);
    } else if (type === "refer") {
      setValue("criteriaName", "instant");
    } else if (type === "signup") {
      setValue("isOnlyNewPlayer", 1);
    }
    if (action === 'reset_subtype') {
      setValue("minDeposit", null);
      setValue("maxDeposit", null);
      setValue("criteriaName", null);
      setValue("criteriaRangeFrom", null);
      setValue("criteriaRangeTo", null);
      if (type === 'number') {
        setValue('promoFrequency', 'once');
        setValue('maxUsage', -2);
      } else {
        setValue('promoFrequency', 'limited');
        setValue('maxUsage', 1);
      }
    }
  };

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-5"
        >
          <div className="cardcss">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-ev-green">
                <h4>Custom Details</h4>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="me-2">
                  <Select
                      className="!w-full"
                      label="Priority"
                      name="priority"
                      options={priorityOptions}
                      register={register}
                  />
                </div>
              </div>
              {/*<div className="flex items-center justify-center gap-2">*/}
              {/*  <label className={`text-sm text-ev-secondary pb-[4px]`}>*/}
              {/*    In Promotion Page? :*/}
              {/*  </label>*/}
              {/*  <Controller*/}
              {/*    name="inPromotion"*/}
              {/*    control={control}*/}
              {/*    render={({ field }) => (*/}
              {/*      <Switch*/}
              {/*        checked={field.value === 1}*/}
              {/*        onChange={(checked: any) =>*/}
              {/*          setValue("inPromotion", checked ? 1 : 0)*/}
              {/*        }*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div className="grid grid-cols-3 items-start gap-3 pt-2">
              <Select
                className="!w-full"
                label="Custom Main Type"
                name="campaignSubCategory"
                placeholder="Select a main type"
                disabled={isDisabled}
                options={typeOptions}
                register={register}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue("campaignSubCategory", value);
                  resetData("custom_main_type", value);
                }}
              />
              <Input
                placeholder="Enter Custom Name"
                className="!w-full"
                name="campaignName"
                label="Custom Name"
                register={register}
                disabled={isDisabled}
              />
              <Input
                placeholder="Enter Promo Code"
                className="!w-full"
                name="promoCode"
                label="Promo Code"
                disabled={isDisabled}
                register={register}
                errors={errors}
              />
              <DropDown
                name="domainId"
                label="Domain"
                options={prepareDomainOptions()}
                isDisabled={isDisabled}
                value={watch("domainId")}
                register={register}
                onChange={(e: any, i: any) => {
                  setValue("domainId", e);
                  onGetPlayerList();
                }}
              />
              {watch("domainId") ? (
                <Select
                  className="!w-full"
                  name="customOption"
                  label="Custom Option"
                  disabled={isDisabled}
                  options={customOptions}
                  register={register}
                />
              ) : null}
              {watch("customOption") === "players" ? (
                <div>
                  <div className="flex items-end justify-end">
                    <div className="w-full">
                      <DropDown
                        name="playerId"
                        label="Players"
                        options={preparePlayerOptions()}
                        isMulti
                        value={watch("playerId")}
                        register={register}
                        isDisabled={isDisabled}
                        onChange={(e: any, i: any) => {
                          setValue(
                            "playerId",
                            e?.map((item: any, index: any) => item)
                          );
                        }}
                      />
                    </div>
                    <div className="w-auto">
                      <Button
                        className="!w-auto ms-1"
                        variant="Primary"
                        disabled={isDisabled}
                        onClick={() => {
                          setIsUploadCSVModalOpen(true);
                        }}
                      >
                        <MdFileUpload />
                      </Button>
                    </div>
                  </div>
                  <label className="font-semibold text-red-500 text-sm">
                    (Note: If Empty Then Consider As All Players.)
                  </label>
                </div>
              ) : null}
              {watch("customOption") === "vipLevels" ? (
                <div>
                  <DropDown
                    name="vipUserLevel"
                    label="VIP Levels"
                    options={vipUserLevelOption}
                    isMulti
                    value={watch("vipUserLevel")}
                    register={register}
                    isDisabled={isDisabled}
                    onChange={(e: any, i: any) => {
                      setValue(
                        "vipUserLevel",
                        e?.map((item: any, index: any) => item)
                      );
                    }}
                  />
                  <label className="font-semibold text-red-500 text-sm">
                    (Note: If Empty Then Consider As All Levels.)
                  </label>
                </div>
              ) : null}
              {watch("playerId").length <= 0 ? <Select
                  className="!w-full"
                  label="Is Only For New Player?"
                  name="isOnlyNewPlayer"
                  disabled={isDisabled}
                  options={questionOptions}
                  register={register}
              /> : null}
              <Select
                className="!w-full"
                name="campaignType"
                label="Custom Type"
                placeholder="Select a custom type"
                options={campaignTypeOptions}
                register={register}
                errors={errors}
                disabled={isDisabled}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue("campaignType", value);
                  resetData("custom_type", value);
                }}
              />
              {watch("campaignType") === "deposit" ? (
                // || watch("campaignType") === "loyalty"
                <Select
                  className="!w-full"
                  placeholder="Please Select"
                  name="campaignSubType"
                  label="Custom Sub Type"
                  disabled={isDisabled}
                  options={
                    // watch("campaignType") === "loyalty"
                    //   ? loyaltySubTypeOptions
                    //   :
                    campaignSubTypeOptions
                  }
                  register={register}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue("campaignSubType", value);
                    resetData("reset_subtype", value);
                  }}
                />
              ) : null}
              {watch("campaignSubType") === "number" ? (
                <Input
                  type="number"
                  className="!w-full"
                  placeholder="Enter Deposite Number"
                  name="frequencySettings"
                  label="Deposite Number"
                  register={register}
                  disabled={isDisabled}
                />
              ) : null}
              {watch("campaignType") === "refer" ? (
                <Select
                  className="!w-full"
                  // placeholder="Please Select"
                  name="criteriaName"
                  label="Criteria Name"
                  disabled={isDisabled}
                  options={referCriteriaOptions}
                  register={register}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue("criteriaName", value);
                    resetData("reset_frequency", null);
                  }}
                />
              ) : null}
              {watch("campaignType") === "sports" ||
              watch("campaignType") === "casino" ? (
                  <>
                    <Select
                        className="!w-full"
                        placeholder="Please Select"
                        name="criteriaName"
                        label="Criteria Name"
                        disabled={isDisabled}
                        options={betCriteriaOptions}
                        register={register}
                        onChange={(e) => {
                          const value = e.target.value;
                          setValue("criteriaName", value);
                          resetData('reset_frequency', 'criteria_sports_casino');
                        }}
                    />
                    {watch("criteriaName") ? (
                        <>
                          <Input
                              type="number"
                              placeholder="Enter Min Bet Amount"
                              className="!w-full"
                              name="minBetAmount"
                              label="Min Bet Amount"
                              register={register}
                              errors={errors}
                              disabled={isDisabled}
                          />
                          <Input
                              type="number"
                              placeholder="Enter Roll Over"
                              className="!w-full"
                              name="rollOver"
                              label="Roll Over"
                              register={register}
                              errors={errors}
                              disabled={isDisabled}
                          />
                          {watch("campaignType") === "sports" ? (
                              <div className="flex gap-2 items-end">
                                <Input
                                    type="number"
                                    placeholder="Enter Min Odds"
                                    className="!w-full"
                                    name="minOdds"
                                    label="Min Odds"
                                    register={register}
                                    disabled={isDisabled}
                                />
                                <Input
                                    type="number"
                                    placeholder="Enter Max Odds"
                                    className="!w-full"
                                    name="maxOdds"
                                    label="Max Odds"
                                    register={register}
                                    disabled={isDisabled}
                                />
                              </div>
                          ) : null}
                        </>
                    ) : null}
                  </>
              ) : null}
              {watch("campaignType") === "deposit" && watch("campaignSubType") ? (
                  <div className="flex gap-2 items-end">
                    <Input
                        placeholder="Enter Min Deposit"
                        type="number"
                        className="!w-full"
                        name="minDeposit"
                        label="Min Deposit"
                        register={register}
                        disabled={isDisabled}
                    />
                    <Input
                        placeholder="Enter Max Deposit"
                        type="number"
                        className="!w-full"
                        name="maxDeposit"
                        label="Max Deposit"
                        register={register}
                        disabled={isDisabled}
                    />
                  </div>
              ) : null}
              {watch("campaignType") === "deposit" && watch("campaignSubType") ? (
                  <Select
                      className="!w-full"
                      placeholder="Please Select"
                      name="criteriaName"
                      label="Criteria Name"
                      disabled={isDisabled}
                      options={criteriaNameOptions}
                      register={register}
                      onChange={(e) => {
                        const value = e.target.value;
                        setValue("criteriaName", value);
                        resetData('reset_frequency', 'criteria_deposit');
                      }}
                  />
              ) : null}
              {watch("campaignType") === "deposit" && watch("criteriaName") ? (
                  <div>
                    <label className="text-sm text-ev-secondary pb-[4px]">
                      Criteria Range
                    </label>
                    <div className="flex gap-2 items-center">
                      <Input
                          type="number"
                          className="!w-full"
                          placeholder="Enter Criteria Range"
                          name="criteriaRangeFrom"
                          register={register}
                          disabled={isDisabled}
                      />
                      <span className="text-sm text-ev-secondary">To</span>
                      <Input
                          type="number"
                          className="!w-full"
                          placeholder="Enter Criteria Range To"
                          name="criteriaRangeTo"
                          register={register}
                          disabled={isDisabled}
                      />
                    </div>
                  </div>
              ) : null}
              {/*{watch("campaignType") === "deposit" &&*/}
              {/* watch("criteriaName")*/}
              {/*    ? (*/}
              {/*        <Input*/}
              {/*            placeholder="Enter Roll Over"*/}
              {/*            className="!w-full"*/}
              {/*            name="rollOver"*/}
              {/*            label="Roll Over"*/}
              {/*            register={register}*/}
              {/*        />*/}
              {/*) : null}*/}
              {/*{watch("campaignType") === "loyalty" ? (*/}
              {/*    <Select*/}
              {/*        className="!w-full"*/}
              {/*        placeholder="Please Select"*/}
              {/*        name="vipUserLevel"*/}
              {/*        label="VIP User"*/}
              {/*        disabled={isDisabled}*/}
              {/*        options={vipUserLevelOption}*/}
              {/*        register={register}*/}
              {/*    />*/}
              {/*) : null}*/}
              {/* {watch("campaignType") === "loyalty" ? (
                <Select
                  className="!w-full"
                  placeholder="Please Select"
                  name="frequencySettings"
                  label="Frequency Setting"
                  disabled={isDisabled}
                  options={frequencySettingOptions2}
                  register={register}
                />
              ) : null} */}
              {/* {watch("campaignType") === "loyalty" &&
              (watch("frequencySettings") === "Weekly" ||
                watch("frequencySettings") === "Monthly") ? (
                <Select
                  className="!w-full"
                  placeholder="Select Days"
                  name="days"
                  label="Days"
                  disabled={isDisabled}
                  options={prepareDaysOptions()}
                  register={register}
                />
              ) : null} */}
              {/* {watch("campaignType") === "loyalty" ? (
                <Input
                  className="!w-full"
                  placeholder="Enter User Level"
                  name="userLevel"
                  label="User Level"
                  register={register}
                />
              ) : null} */}
              {/* <Select
                className="!w-full"
                placeholder="Please Select"
                name="vipUserLevel"
                label="VIP User Level"
                options={vipUserLevel}
                register={register}
              /> */}
              {watch("campaignType") !== "signup" ? (
                <>
                  {!watch("campaignType") ||
                  (watch("campaignType") &&
                    !watch("criteriaName") &&
                    !watch("campaignSubType")) ? (
                    <Select
                      className="!w-full"
                      label="Custom Frequency"
                      name="promoFrequency"
                      disabled={isDisabled}
                      options={onlyLimitedfrequencyOptions}
                      register={register}
                    />
                  ) : (
                      <>
                        {watch('campaignSubType') === 'number' ? <Select
                            className="!w-full"
                            label="Custom Frequency"
                            name="promoFrequency"
                            disabled={isDisabled}
                            options={frequencyNumberDepositOptions}
                            register={register}
                        /> : <Select
                            className="!w-full"
                            label="Custom Frequency"
                            name="promoFrequency"
                            disabled={isDisabled}
                            options={frequencyOptions}
                            register={register}
                        />}
                      </>
                  )}
                  {watch("promoFrequency") === "limited" ? (
                    <Input
                      type="number"
                      placeholder="Enter Promo Code Usage"
                      className="!w-full"
                      name="maxUsage"
                      label="Promo Code Usage"
                      disabled={isDisabled}
                      register={register}
                      errors={errors}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <div className="cardcss">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-ev-green">
                <h4>Custom Insights</h4>
              </div>
              <div className="flex items-center h-full space-x-4">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Custom Category :
                </label>
                {bonusTypeOptions.map((option) => {
                  if (
                    ((watch("campaignSubCategory") && !watch("campaignType")) ||
                      watch("campaignType") === "signup" ||
                      watch("campaignType") === "sports" ||
                      watch("campaignType") === "casino" ||
                      (watch("campaignType") === "refer" &&
                        watch("criteriaName") === "instant")) &&
                    option?.value === "percentage"
                  ) {
                    return <Fragment key={option.value}></Fragment>;
                  }
                  return (
                    <RadioButton
                      key={option.value}
                      label={option.label}
                      value={option.value}
                      disabled={isDisabled}
                      checked={bonusType === option.value}
                      onChange={() => {
                        const bonusAmount = getValues()?.bonusAmount;
                        if (option.value === "percentage") {
                          if ((+bonusAmount || 0) >= 0) {
                            BonusTypeChange(option.value);
                          }
                        } else {
                          BonusTypeChange(option.value);
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="grid items-start grid-cols-3 gap-3 pt-2">
              <Input
                placeholder={`Enter Custom ${
                  watch("bonusType") === "percentage" ? "Percentage" : "Amount"
                } `}
                className="!w-full"
                type={watch("bonusType") === "percentage" ? "text" : "number"}
                name="bonusAmount"
                label={`Custom ${
                  watch("bonusType") === "percentage" ? "Percentage" : "Amount"
                } `}
                value={
                  watch("bonusType") === "percentage"
                    ? percentage
                    : watch("bonusAmount") || ""
                }
                disabled={isDisabled}
                onChange={(e) => {
                  const bonusType = getValues()?.bonusType;
                  if (bonusType === "percentage") {
                    handleInputChange(e);
                  } else {
                    setValue("bonusAmount", e.target.value);
                  }
                }}
              />
              {watch("bonusType") === "percentage" ? (
                <Input
                  placeholder="Enter Custom Maximum Amount"
                  type="number"
                  className="!w-full"
                  name="bonusCapAmount"
                  label="Custom Maximum Amount"
                  register={register}
                  disabled={isDisabled}
                />
              ) : null}
              {/*<Select*/}
              {/*  className="!w-full"*/}
              {/*  name="dateType"*/}
              {/*  label="Type"*/}
              {/*  options={dateTypeOptions}*/}
              {/*  register={register}*/}
              {/*  disabled={isValidityExpired}*/}
              {/*  onChange={(e) => {*/}
              {/*    setValue("dateType", e.target.value);*/}
              {/*    setValue("validityDays", null);*/}
              {/*    setValue("expiryDate", null);*/}
              {/*    setValue("expiryTime", null);*/}
              {/*  }}*/}
              {/*/>*/}
              {/*{watch("dateType") === "validityDays" ? (*/}
              {/*  <Input*/}
              {/*    className="!w-full"*/}
              {/*    placeholder="Enter Validity Days"*/}
              {/*    name="validityDays"*/}
              {/*    label="Validity Days"*/}
              {/*    type="number"*/}
              {/*    register={register}*/}
              {/*    disabled={isValidityExpired}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <div>*/}
              {/*    <label className={`text-sm text-ev-secondary pb-[4px]`}>*/}
              {/*      Custom Expiry Date / Time*/}
              {/*    </label>*/}
              {/*    <div className="flex">*/}
              {/*      <div className="w-1/2">*/}
              {/*        <ControlledDatePicker*/}
              {/*          inputClassName="rounded-e-[0px]"*/}
              {/*          name="expiryDate"*/}
              {/*          placeholder="Expiry Date"*/}
              {/*          control={control}*/}
              {/*          minDate={new Date()}*/}
              {/*          errors={errors}*/}
              {/*          disabled={isValidityExpired}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*      <div className="w-1/2">*/}
              {/*        <ControlledTimePicker*/}
              {/*          inputClassName="bonus-time"*/}
              {/*          name="expiryTime"*/}
              {/*          placeholder="Expiry Time"*/}
              {/*          control={control}*/}
              {/*          errors={errors}*/}
              {/*          disabled={isValidityExpired}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
              {watch("campaignSubCategory") === "bonus" ? (<Input
                  className="!w-full"
                  placeholder="Enter Validity Days"
                  name="validityDays"
                  label="Validity Days"
                  type="number"
                  register={register}
                  disabled={isDisabled}
                  errors={errors}
              />) : null}
            </div>
          </div>
          {watch("campaignSubCategory") === "bonus" ? (
            <>
              <div className="cardcss">
                <div className="flex justify-between">
                  <div className="text-lg font-semibold text-[#037756]">
                    <h4>Bonus Calculation Per Wallet</h4>
                  </div>
                  {errors?.all_wallet ? (
                    <span className="text-red-500 text-sm">
                      {errors?.all_wallet?.message}
                    </span>
                  ) : null}
                </div>
                <div className="grid items-start grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-3 pt-2">
                  {Object.entries(percentages)?.map(([key, value]) => {
                    if (
                      watch("campaignType") === "sports" &&
                      key !== "sports_wallet"
                    ) {
                      return <Fragment key={key}></Fragment>;
                    }
                    if (
                      watch("campaignType") === "casino" &&
                      key !== "casino_wallet"
                    ) {
                      return <Fragment key={key}></Fragment>;
                    }
                    return (
                        <Fragment key={'calc' + key}>
                          <Input
                              key={key}
                              placeholder="Percentage"
                              className="!w-full"
                              type="number"
                              name={key}
                              label={walletKeys[key]}
                              value={value}
                              rightIcon="Percentage"
                              errors={errors}
                              disabled={isDisabled}
                              onChange={(e) => {
                                handleWalletInputChange(key, e.target.value);
                              }}
                          />
                          <Select
                              className="w-auto"
                              label="Is Previous Bonus Forfeited?"
                              name={key + '_previous_forfeited'}
                              disabled={isDisabled}
                              options={questionOptions}
                              register={register}
                              onChange={(e) => {
                                handleWalletSelectChange(key + '_previous_forfeited', e.target.value);
                              }}
                          />
                        </Fragment>
                    );
                  })}
                  <div className="flex gap-2 items-end">
                    <span className="pb-[10px]">=</span>
                    <Input
                      placeholder="Percentage"
                      className="!w-full"
                      type="number"
                      disabled
                      name="total"
                      label="Total"
                      value={totalWallet}
                      rightIcon="Percentage"
                      errors={errors}
                      style={{
                        border: `${
                          totalWallet === 100
                            ? "1px solid green"
                            : totalWallet > 100
                            ? "1px solid red"
                            : totalWallet < 100 && totalWallet !== 0
                            ? "1px solid #ffab00"
                            : totalWallet === 0
                            ? ""
                            : ""
                        }`,
                      }}
                    />
                  </div>
                </div>
              </div>
              {+percentages?.sports_wallet > 0 && (
                <div className="cardcss">
                  <div className="flex justify-between">
                    <div className="text-lg font-semibold text-[#037756]">
                      <h4>Conversion - Sports</h4>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-start gap-3 pt-2">
                    {/*<Input*/}
                    {/*  type="number"*/}
                    {/*  placeholder="Enter Max Bonus Conversion Amount"*/}
                    {/*  className="!w-full"*/}
                    {/*  name="maxBonusConversionSports"*/}
                    {/*  label="Max Bonus Conversion Amount"*/}
                    {/*  register={register}*/}
                    {/*  errors={errors}*/}
                    {/*  disabled={isDisabled}*/}
                    {/*/>*/}
                    <Input
                      type="number"
                      placeholder="Enter Min Bet Amount"
                      className="!w-full"
                      name="minBetAmountSports"
                      label="Min Bet Amount"
                      register={register}
                      errors={errors}
                      disabled={isDisabled}
                    />
                    <Input
                      type="number"
                      placeholder="Enter Roll Over"
                      className="!w-full"
                      name="rollOverSports"
                      label="Roll Over"
                      register={register}
                      errors={errors}
                      disabled={isDisabled}
                    />
                    <div className="flex gap-2 items-end">
                      <Input
                        type="number"
                        placeholder="Enter Min Odds"
                        className="!w-full"
                        name="minOddsSports"
                        label="Min Odds"
                        register={register}
                        disabled={isDisabled}
                      />
                      <Input
                        type="number"
                        placeholder="Enter Max Odds"
                        className="!w-full"
                        name="maxOddsSports"
                        label="Max Odds"
                        register={register}
                        disabled={isDisabled}
                      />
                    </div>
                    <Input
                      type="number"
                      placeholder="Enter Wagering Contribution"
                      className="!w-full"
                      name="wageringContributionSports"
                      label="Wagering Contribution(%)"
                      register={register}
                      errors={errors}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              )}
              {+percentages?.casino_wallet > 0 && (
                <div className="cardcss">
                  <div className="flex justify-between">
                    <div className="text-lg font-semibold text-[#037756]">
                      <h4>Conversion - Casino</h4>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 items-start gap-3 pt-2">
                    {/*<Input*/}
                    {/*  type="number"*/}
                    {/*  placeholder="Enter Max Bonus Conversion Amount"*/}
                    {/*  className="!w-full"*/}
                    {/*  name="maxBonusConversionCasino"*/}
                    {/*  label="Max Bonus Conversion Amount"*/}
                    {/*  register={register}*/}
                    {/*  errors={errors}*/}
                    {/*  disabled={isDisabled}*/}
                    {/*/>*/}
                    <Input
                      type="number"
                      placeholder="Enter Min Bet Amount"
                      className="!w-full"
                      name="minBetAmountCasino"
                      label="Min Bet Amount"
                      register={register}
                      errors={errors}
                      disabled={isDisabled}
                    />
                    <Input
                      type="number"
                      placeholder="Enter Roll Over"
                      className="!w-full"
                      name="rollOverCasino"
                      label="Roll Over"
                      register={register}
                      errors={errors}
                      disabled={isDisabled}
                    />
                    <Input
                      type="number"
                      placeholder="Enter Live Casino"
                      className="!w-full"
                      name="liveCasinoCasino"
                      label="Live Casino(%)"
                      register={register}
                      disabled={isDisabled}
                    />
                    <Input
                      type="number"
                      placeholder="Enter Slot"
                      className="!w-full"
                      name="slotCasino"
                      label="Slot(%)"
                      register={register}
                      disabled={isDisabled}
                    />
                    <Input
                      type="number"
                      placeholder="Enter Table Games"
                      className="!w-full"
                      name="tableGamesCasino"
                      label="Table Games(%)"
                      register={register}
                      disabled={isDisabled}
                    />
                    <Input
                      type="number"
                      placeholder="Enter Virtual Games"
                      className="!w-full"
                      name="virtualGamesCasino"
                      label="Virtual Games(%)"
                      register={register}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          <div className="cardcss">
            <div className="text-lg font-semibold text-[#037756]">
              <h4>Other Details</h4>
            </div>
            <div className="grid grid-cols-2 items-start gap-3 pb-3">
              <div className="textareacss">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Description
                </label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <textarea
                      className="p-2 w-full bg-chatlook-secondary placeholder-ev-white text-xs text-ev-white h-10 rounded outline-none border-[1px] border-[#222A40] focus:border-chatlook-secondary"
                      placeholder="Enter Description"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="proof-sec">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Upload Banner Image ( Max image size 5MB)
                  {/*Upload Banner Image ( Max image size 5MB - Resolution 530px **/}
                  {/*237px )*/}
                  {/*<span*/}
                  {/*  className={*/}
                  {/*    (imageData?.width === 530 && imageData?.height === 237) ||*/}
                  {/*    (imageData?.width === 0 && imageData?.height === 0)*/}
                  {/*      ? `text-sm text-ev-lightgray pb-[4px]`*/}
                  {/*      : `text-sm text-red-600  pb-[4px]`*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*</span>*/}
                  {` ${imageData?.width} x ${imageData?.height} `}
                </label>
                <div className="file-input">
                  {/* <Controller
                    name="image"
                    control={control}
                    render={({ field }) => ( */}
                  <>
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      className="file-input__input"
                      onChange={(e: any) => {

                        try {
                          const file: any = e.target.files?.[0];
                          const maxSize = 5 * 1024 * 1024;

                          if (file && file?.size < maxSize) {
                            const reader = new FileReader();
                            reader.onload = (e: any) => {
                              const image: any = new Image();
                              image.src = e.target.result;
                              image.onload = () => {
                                setImageData({
                                  ...imageData,
                                  size: file.size,
                                  width: image.width,
                                  height: image.height,
                                });
                                setImage(URL.createObjectURL(file));

                                setValue("image", file);
                              };
                            };
                            reader.readAsDataURL(file);
                          }
                          setImageData({ ...imageData, size: file?.size });
                        } catch (err) {
                          console.error("======", err);
                        }
                      }}
                    />
                    <label
                      className="file-input__label"
                      htmlFor="file-input"
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      onClick={(e) => {
                        if (image) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                    >
                      <span className="font-semibold pt-2 text-[#037756] text-xs">
                        {watch("image")?.name
                          ? `${watch("image")?.name}`
                          : "Click to Upload Banner Image"}{" "}
                        {image ? null : (
                          <Icon
                            name="UploadIcon"
                            className="w-10 h-10 m-auto"
                          />
                        )}
                      </span>
                      {image ? (
                        <>
                          {/* <img
                                src={image}
                                alt="Selected file"
                                className="w-100 m-auto py-2 h-40"
                              /> */}
                          <IoMdCloseCircle
                            size={25}
                            className="absolute right-1 top-1"
                            style={{ color: "#037756" }}
                            onClick={(e) => closeImage(e)}
                          />
                        </>
                      ) : null}
                    </label>
                  </>
                  {/* )}
                  > */}
                </div>
                {errors?.image ? (
                  <span className="text-red-500 text-sm">
                    {errors?.image?.message}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="grid grid-cols-2 items-start gap-3 pb-3">
              <div className="ckeditor-container">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Rules
                </label>
                <Editor
                  style={{ height: 100 }}
                  placeholder="Enter rules"
                  value={watch("rules")}
                  onTextChange={(e) => setValue("rules", e.htmlValue)}
                />
              </div>
              <div className="ckeditor-container">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Terms & Conditions
                </label>
                <Editor
                  style={{ height: 100 }}
                  placeholder="Enter Terms & Conditions"
                  value={watch("tC")}
                  onTextChange={(e) => setValue("tC", e.htmlValue)}
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3 pt-2">
              <div className="ckeditor-container">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Start Date / Time
                </label>
                <div className="flex">
                  <div className="w-1/2">
                    <ControlledDatePicker
                      name="startDate"
                      inputClassName="rounded-e-[0px]"
                      // label="Start Date"
                      placeholder="Start Date"
                      selectesStart
                      disabled={isDisabled}
                      startDate={watch("startDate")}
                      endDate={watch("endDate")}
                      maxDate={watch("endDate")}
                      minDate={new Date()}
                      control={control}
                      errors={errors}
                    />
                  </div>

                  <div className="w-1/2">
                    <ControlledTimePicker
                      inputClassName="bonus-time border-l-0"
                      name="startTime"
                      placeholder="Select Start Time"
                      disabled={isDisabled}
                      control={control}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="ckeditor-container">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  End Date / Time
                </label>
                <div className="flex">
                  <div className="w-1/2">
                    <ControlledDatePicker
                      inputClassName="rounded-e-[0px]"
                      name="endDate"
                      placeholder="End Date"
                      selectsEnd
                      startDate={watch("startDate")}
                      endDate={watch("endDate")}
                      minDate={watch("startDate") || new Date()}
                      control={control}
                      errors={errors}
                      disabled={isCampaignExpired}
                    />
                  </div>

                  <div className="w-1/2">
                    <ControlledTimePicker
                      inputClassName="bonus-time border-l-0"
                      name="endTime"
                      // label="End Time"
                      placeholder="Select End Time"
                      minTime={
                        watch("startDate")?.toISOString() ===
                        watch("endDate")?.toISOString()
                          ? watch("startTime")
                          : undefined
                      }
                      control={control}
                      errors={errors}
                      disabled={isCampaignExpired}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="flex items-center justify-center gap-2">*/}
              {/*  <label>Status :</label>*/}
              {/*  <Controller*/}
              {/*    name="status"*/}
              {/*    control={control}*/}
              {/*    render={({ field }) => (*/}
              {/*      <Switch*/}
              {/*        checked={field.value === 1}*/}
              {/*        onChange={(checked: any) =>*/}
              {/*          setValue("status", checked ? 1 : 0)*/}
              {/*        }*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            {/* {id ? null : watch('playerId').length > 0 && watch('vipUserLevel').length <= 0 && !watch('campaignSubType') ? <div className="flex flex-col gap-2 my-4">
              <div className="flex gap-3">
                <Controller
                    name="is_notify"
                    control={control}
                    render={({field}) => (
                        <Switch
                            checked={field.value === 1}
                            onChange={(checked: any) =>
                                setValue("is_notify", checked ? 1 : 0)
                            }
                        />
                    )}
                />
                <label>Is Notify?</label>
              </div>
              {watch('is_notify') ? <div className="textareacss">
                    <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Description
                </label>
                <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <textarea
                            className="p-2 w-full text-base h-10 rounded outline-none border-[1px] border-[#D0D5DD] focus:border-chatlook-secondary"
                            placeholder="Enter Description"
                            {...field}
                        />
                    )}
                />
              </div> : null}
            </div> : null} */}
            <div className="flex items-center justify-end gap-2">
              <Button type="submit" className="mt-[10px] !h-9 !text-sm">
                {loading ? (
                  <Loader loaderClassName="create-edit-bonus" />
                ) : id ? (
                  "Update"
                ) : (
                  "Add"
                )}
              </Button>
              <Button
                variant="Transaparent"
                className="mt-[10px]  !text-sm !h-9"
                onClick={() => {
                  reset();
                  setImage(id ? currentBonus?.image_url : null);
                  setImageData({
                    width: 0,
                    height: 0,
                    size: 0,
                  });
                  setBonusTypeOption("");
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </form>
      </div>
      <UploadCSV
        isOpen={isUploadCSVModalOpen}
        onClose={() => {
          setIsUploadCSVModalOpen(false);
        }}
        onPlayers={(data: any) => {
          if (data) {
            setValue(
              "playerId",
              playerListOption?.player_list
                ?.filter((item: any) =>
                  Object.values(data).includes(item?.player_id)
                )
                .map((item: any) => item?.player_id)
            );
          }
        }}
      />
    </>
  );
};

export default CustomForm;
