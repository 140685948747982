import { useEffect, useState, Fragment, useRef } from "react";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  ControlledInput,
  Export,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
  getStatusTypes,
} from "../../ui";

// PROJECT IMPORT
import {
  dateFormatter,
  responseToaster,
} from "../../helperFunctions";
import { useGetBonusHistoryMutation } from "./bonusHistoryApi";
import { useBonusHistoryItems } from "./bonusHistorySlice";
import { FILTER } from "../../constant";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import BonusActivityModal from "./BonusActivityModal";
import BonusProgressModal from "./BonusProgressModal";

const statusOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

const BonusHistory = () => {
  const bonusHistoryData = useBonusHistoryItems();

  const [filter, setFilter] = useState(FILTER);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [getBonusHistory, { isLoading }] = useGetBonusHistoryMutation();
  const [showDiv, setShowDiv] = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const isInitialMount = useRef(true);
  const [isBonusActivityModalOpen, setIsBonusActivityModalOpen] = useState(false);
  const [isBonusProgressModalOpen, setIsBonusProgressModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [progressData, setProgressData] = useState({});
  useEffect(() => {
    setTableData(
      bonusHistoryData?.data?.length ? [...bonusHistoryData?.data] : []
    );
  }, [bonusHistoryData]);

  const columns = [
    {
      title: "ID",
      name: "id",
    },
    {
      title: "Player Info",
      name: "player_id",
      Cell: (item: any) => {
        return (
            <div>
              <span>{item?.username}</span>
              <br/>
              <small>( {item?.player_id} )</small>
            </div>
        );
      },
    },
    {
      title: "Campaign",
      name: "bonus_type",
      Cell: (item: any) => {
        return (
            <div>
              <span>{item?.campaign_name}</span>
              <br/>
              <small>( {item?.bonus_type} )</small>
              <br/>
              <small className="font-medium">-- {item?.promo_code} --</small>
            </div>
        );
      },
    },
    {
      title: "Max Usage",
      name: "max_usage",
      Cell: (item: any) => {
        if (item.max_usage === -2) {
          return (
              <span className="font-semibold">Once</span>
          );
        } else if (item.max_usage === -1) {
          return (
              <span className="font-semibold">Unlimited</span>
          );
        } else {
          return (
              <span className="font-semibold">{item.max_usage} <sup>times</sup></span>
          );
        }
      },
    },
    {
      title: "Activated",
      name: "is_activated",
      Cell: (item: any) => {
        return (
          <span
            className={`${getStatusTypes(
              item?.is_activated ? "Success" : "Failed"
            )}`}
          >
            {item?.is_activated ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: "Available",
      name: "is_available",
      Cell: (item: any) => {
        return (
          <span
            className={`${getStatusTypes(
              item?.is_available ? "Success" : "Failed"
            )}`}
          >
            {item?.is_available ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: "Claimed",
      name: "is_claimed",
      Cell: (item: any) => {
        return (
          <span
            className={`${getStatusTypes(
              item?.is_claimed ? "Success" : "Failed"
            )}`}
          >
            {item?.is_claimed ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: "Used",
      name: "is_used",
      Cell: (item: any) => {
        return (
          <span
            className={`${getStatusTypes(
              item?.is_used ? "Success" : "Failed"
            )}`}
          >
            {item?.is_used ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: "Deleted",
      name: "is_deleted",
      Cell: (item: any) => {
        return (
          <span
            className={`${getStatusTypes(
              item?.is_deleted ? "Success" : "Failed"
            )}`}
          >
            {item?.is_deleted ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: "Progress",
      name: "progress",
      Cell: (item: any) => {
        if ((item.bonus_status).toLowerCase() === 'convert') {
          return (
              <div
                className="cursor-pointer flex gap-2"
                onClick={() => {
                setIsBonusProgressModalOpen(true);
                setProgressData(item);
              }}>
                {item?.progress?.sports ? <div>
                  <div className="mb-1 text-sm font-medium dark:text-white">Sports</div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                         style={{ width: `${item?.progress?.sports?.progress}%` }}> {item?.progress?.sports?.progress}%
                    </div>
                  </div>
                </div> : null}
                {item?.progress?.casino ? <div>
                  <div className="mb-1 text-sm font-medium dark:text-white">Casino</div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                         style={{ width: `${item?.progress?.casino?.progress}%` }}> {item?.progress?.casino?.progress}%
                    </div>
                  </div>
                </div> : null}
              </div>
          );
        } else {
          return (
              <span>--</span>
          );
        }
      },
    },
    {
      title: "Status",
      name: "bonus_status",
      Cell: (item: any) => {
        if (item.bonus_status) {
          return (
              <span
                  className="font-semibold underline cursor-pointer"
                  onClick={() => {
                    setIsBonusActivityModalOpen(true);
                    setCurrentData(item);
                  }}
              >
                {item?.bonus_status}
              </span>
          );
        } else {
          return (
              <span>--</span>
          );
        }
      },
    },
    {
      title: "Last Activity",
      name: "activity_date",
      Cell: (item: any) => {
        if(item.activity_date) {
          return (
              <span>
            {dateFormatter(item?.activity_date)}
                <br />
            <small>( {item?.activity_date_readable} )</small>
          </span>
          );
        } else {
          return (
              <span>--</span>
          )
        }
      },
    },
    {
      title: "Last Visit Site",
      name: "last_visit_site",
      Cell: (item: any) => {
        if(item.last_visit_site) {
          return (
              <span>
            {dateFormatter(item?.last_visit_site)}
                <br />
            <small>( {item?.last_visit_site_readable} )</small>
          </span>
          );
        } else {
          return (
              <span>--</span>
          )
        }
      },
    },
    {
      title: "Received On",
      name: "created_at",
      Cell: (item: any) => {
        return (
            <span>
            {dateFormatter(item?.created_at)}
              <br />
            <small>( {item?.created_at_readable} )</small>
          </span>
        );
      },
    },
  ];

  const { register, handleSubmit, setValue, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        type: "All",
        activated: "All",
        available: "All",
        claimed: "All",
        used: "All",
        deleted: "All",
        fltstatus: "All",
      },
    });

  const onGetData = async () => {
    try {
      const values = getValues();
      const { date, searchData, ...rest } = values;
      const payload: any = {
        ...filter,
        filter_data: {
          ...rest,
          searchData: searchTerm,
          createdStartDate: dateFormatter(values?.date?.[0],"date"),
          createdEndDate: dateFormatter(values?.date?.[1], "date"),
        },
      };
      delete payload["isFilter"];
      const res: any = await getBonusHistory(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  const prepareTypeOptions = () => {
    const options: any = bonusHistoryData?.type?.map((val: any) => {
      return {
        value: val?.bonus_type,
        label: val?.bonus_type,
      };
    });
    return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
  };

  const prepareBonusStatusOptions = () => {
    const options: any = bonusHistoryData?.bonus_status?.map((val: any) => {
      return {
        value: val?.bonus_status,
        label: val?.bonus_status,
      };
    });
    return options?.length ? [{value: "All", label: "All"}, ...options] : [];
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        setFilter({ ...filter, isFilter: false });
      }
    }, 9000);
    return () => clearInterval(IntervalClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-centere justify-between pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv);
              }}
            >
              <CiFilter className="text-lg" />
            </Button>
            <Button
              type="button"
              variant={refresh ? "Primary" : "Transaparent"}
              className={`!text-sm ${
                refresh ? "hover:bg-chatlook-secondary" : "hover:bg-white"
              }`}
              onClick={() => setRefresh(!refresh)}
            >
              <Icon
                name="Refresh"
                className={` ${refresh ? "animate-spin" : ""}`}
              />
            </Button>
            <Export
              variant="Transaparent"
              filename="download.csv"
              data={
                bonusHistoryData?.data?.map((item: any) => ({
                  ...item,
                  created_at:dateFormatter(item?.created_at),
                  is_activated: item?.is_activated ? "Yes" : "No",
                  is_available: item?.is_available ? "Yes" : "No",
                  is_claimed: item?.is_claimed ? "Yes" : "No",
                  is_used: item?.is_used ? "Yes" : "No",
                  is_deleted: item?.is_deleted ? "Yes" : "No",
                })) || []
              }
              headers={
                columns?.map((val) => ({
                  label: val?.title,
                  key: val?.name,
                })) || []
              }
            >
              Export
            </Export>
          </div>
        </div>

        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                name="search"
                label="Search Player"
                placeholder="Player Id"
                register={register}
              />
              <Input
                name="promocode"
                label="Promo Code"
                placeholder="Enter Promo Code"
                register={register}
              />
              <ControlledDatePicker
                name="date"
                label="Date"
                placeholder="Select Date"
                selectsRange
                maxDate={new Date()}
                control={control}
              />
              <Select
                name="type"
                label="Type"
                options={prepareTypeOptions()}
                register={register}
              />
              <Select
                name="activated"
                label="Activated"
                options={statusOptions}
                register={register}
              />
              <Select
                name="available"
                label="Available"
                options={statusOptions}
                register={register}
              />
              <Select
                name="claimed"
                label="Claimed"
                options={statusOptions}
                register={register}
              />
              <Select
                name="used"
                label="Used"
                options={statusOptions}
                register={register}
              />
              <Select
                name="deleted"
                label="Deleted"
                options={statusOptions}
                register={register}
              />
              <Select
                  name="fltstatus"
                  label="Status"
                  options={prepareBonusStatusOptions()}
                  register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div className="App tableborder bg-chatlook-white">
          <Table
            columns={columns}
            isLoading={isLoading}
            data={bonusHistoryData?.data}
            isExpendable={false}
            setData={setTableData}
            count={bonusHistoryData?.total_item || 0}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isFilter: false,
              });
            }}
          >
            {tableData?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    isExpendable={false}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
      <BonusActivityModal
          isOpen={isBonusActivityModalOpen}
          data={currentData}
          onClose={() => {
            setIsBonusActivityModalOpen(false);
            setCurrentData({});
          }}
      />
      <BonusProgressModal
          isOpen={isBonusProgressModalOpen}
          data={progressData}
          onClose={() => {
            setIsBonusProgressModalOpen(false);
            setProgressData({});
          }}
      />
    </>
  );
};

export default BonusHistory;
