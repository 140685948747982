// UI IMPORT
import { Button, Icon, Input, Loader, Password } from "../../ui";

// PROJECT IMPORT
import { useLoginMutation } from "./authApi";
import { LoginFormTypes } from "./types";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { showToaster } from "../../helperFunctions";
import {useState} from "react";
import AlertModal from "../common/AlertModal";

const isOpenModal = () => {
  if (!(localStorage.getItem("isOpen") !== null)) {
    localStorage.setItem("isOpen", "false");
  }
  return localStorage.getItem("isOpen") == "true";
};

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();
  const [isOpen, setIsOpen] = useState<Boolean>(isOpenModal());
  const schema = yup.object({
    email: yup.string().required("Please enter a email Id"),
    password: yup.string().required("Please enter a password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<LoginFormTypes>(schema),
  });

  const onSubmit = async (values: LoginFormTypes) => {
    try {
      const res = await login(values).unwrap();
      showToaster(res?.message);
    } catch (err: any) {
      console.error("Login Error =-=>", err);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <form
        className="grid  h-screen login-wrapper "
        // className="grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 h-screen"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Icon name="TopLeft" className="TopLeft" />
        <Icon name="TopRight" className="TopRight" />
        <Icon name="BottomLeft" className="BottomLeft" />
        <Icon name="BottomRight" className="BottomRight" />
        <div className="bg-chatlook-secondary flex">
          <div className="login-main rounded-xl bg-chatlook-primary w-4/12 md:w-7/12 sm:w-11/12  m-auto ">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-[140px] gap-2">
                <Icon className="w-1/3" name="LogoLastIcon" />
                {/*<span>Logo</span>*/}
              </div>
            </div>
            <div className="welc-text text-center my-6">
              <h4>Log in to your account</h4>
              <p className="mt-1">Welcome back! Please enter your details.</p>
            </div>
            <div className="space-y-5 pb-10">
              <div>
                <label className="text-[#8F93AE] text-sm">Username</label>
                <Input
                  className="!w-full"
                  inputClassName="!h-12"
                  // icon="EmailIcon"
                  name="email"
                  placeholder="Enter Username"
                  // label="Username"
                  errors={errors}
                  register={register}
                />
              </div>
              <div>
                <label className="text-[#8F93AE] text-sm">Password</label>
                <Password
                  className="!w-full"
                  inputClassName="!h-12"
                  name="password"
                  placeholder="Enter Password"
                  // label="Password"
                  errors={errors}
                  register={register}
                />
              </div>
              {/* <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <CheckBox />
                  <label className="text-[#141212] font-semibold text-sm">Remember me</label>
                </div>
                <span className="forgot-pass">Forgot password?</span>
              </div> */}
            </div>
            <div className="flex items-center justify-center w-full">
              <Button type="submit" className="!px-20 w-full">
                Sign in
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="bg-chatlook-secondary flex md:hidden sm:hidden items-center">
          <div className="w-8/12 m-auto">
            <Icon name={"Logohero"} className="max-w-full m-auto" />
            <p className="text-ev-white text-center mt-4">
              Login is the act of entering credentials to access an account or
              system.
            </p>
          </div>
        </div> */}
        <AlertModal
            isLoading={false}
            isOpen={isOpen}
            text="Time's Up! Please Log In Again"
            onHide={() => {
              localStorage.setItem("isOpen", "false");
              setIsOpen(false);
            }}
            onDoneClick={() => {
              localStorage.setItem("isOpen", "false");
              setIsOpen(false);
              window.location.reload();
            }}
        />
      </form>
    </>
  );
};

export default Login;
