import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// UI IMPORT
import {Button, DropDown, Icon, Input, Loader, Select, Switch} from "../../ui";
import { IoMdCloseCircle } from "react-icons/io";

// PROJECT IMPORT
import { useCurrentPromotion } from "./promotionSlice";
import {
  useAddPromotionMutation,
  useUpdatePromotionMutation,
} from "./promotionApi";
import { showToaster } from "../../helperFunctions";
import * as yup from "yup";

// THIRD - PARTY IMPORT
import { Controller, useForm } from "react-hook-form";
import { Editor } from "primereact/editor";
import { useDomainIdData } from "../cashback/cashbackSlice";
import { useGetActiveDomainListQuery } from "../cashback/cashbackApi";
import { yupResolver } from "@hookform/resolvers/yup";
import {priorityOptions} from "../common/mock";

const PromotionForm = () => {
  const domainIdData: any = useDomainIdData();
  const currentPromotion = useCurrentPromotion();
  const navigate = useNavigate();
  const { id } = useParams();

  useGetActiveDomainListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [createPromotion] = useAddPromotionMutation();
  const [updatePromotion] = useUpdatePromotionMutation();

  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [imageData, setImageData] = useState<any>({
    width: 0,
    height: 0,
    size: 0,
  });

  const closeImage = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    clearErrors("image", "");
    setValue("image", "");
    setImageData({ width: 0, height: 0, size: 0 });
    setImage("");
  };

  const prepareDefaultValues = () => {
    if (id) {
      return {
        campaignName: currentPromotion?.name,
        priority: currentPromotion?.priority,
        status: currentPromotion?.status,
        description: currentPromotion?.description || "",
        rules: currentPromotion?.rules || "",
        tC: currentPromotion?.t_c || "",
        image: imageData?.size,
        domainId: currentPromotion?.domain_id
          ? currentPromotion?.domain_id
          : "",
      };
    } else {
      return {
        domainId: "",
      };
    }
  };
  const schema = yup.object({
    image: yup
      .mixed()
      .test(
        "image",
        "File size exceeds the maximum limit (5MB)",
        (value: any) => {
          if (!value) {
            return true;
          }
          return value.size <= 5 * 1024 * 1024;
        }
      ),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
    clearErrors,
    watch,
  }: any = useForm<any>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      ...prepareDefaultValues(),
    },
  });
  const onSubmit = async (values: any) => {
    // if (imageData?.width !== 381 || imageData?.height !== 473) {
    //   setError("image", {
    //     message: "File resolution should be width 381px * height 473px",
    //   });
    //   return;
    // }

    let formData = new FormData();
    formData.append("campaignName", values?.campaignName || "");
    formData.append("priority", values?.priority || 100);
    formData.append("status", values?.status || 0);
    formData.append("description", values?.description || "");
    formData.append("rules", values?.rules || "");
    formData.append("tC", values?.tC || "");
    formData.append("image", values?.image || "");
    formData.append("imageHeight", imageData?.height || 0);
    formData.append("imageWidth", imageData?.width || 0);
    formData.append("imageSize", imageData?.size || 0);
    formData.append("domainId", values?.domainId || "");
    formData.append("category", "promotion");
    setLoading(true);
    if (id) {
      try {
        formData.append("id", currentPromotion?.id);
        const res: any = await updatePromotion(formData).unwrap();
        showToaster(res?.message);
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await createPromotion(formData).unwrap();
        showToaster(res?.message);
        setLoading(false);
        navigate(-1);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const prepareDomainOptions = () => {
    const options = domainIdData?.data?.map((val: any) => {
      return {
        value: val?.id,
        label: val?.domain_name,
      };
    });
    return options;
  };

  useEffect(() => {
    if (id) {
      setImage(currentPromotion?.image_url);
    }
    if (currentPromotion?.image_detail && id) {
      const clonedImageData = JSON.parse(currentPromotion?.image_detail);
      setImageData({
        ...imageData,
        height: clonedImageData?.image_height,
        width: clonedImageData?.image_width,
        size: clonedImageData?.image_size,
      });
    }
    // eslint-disable-next-line
  }, [currentPromotion, id]);

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-5"
        >
          <div className="cardcss">
            <div className="flex justify-between">
              <div className="text-lg font-semibold text-ev-green">
                <h4>Promotion Details</h4>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="me-2">
                  <Select
                      className="!w-full"
                      label="Priority"
                      name="priority"
                      options={priorityOptions}
                      register={register}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 items-start gap-3 pb-3 mt-3">
              <Input
                placeholder="Enter Promotion Name"
                className="!w-full"
                name="campaignName"
                label="Promotion Name"
                register={register}
              />
              <DropDown
                name="domainId"
                label="Domain"
                options={prepareDomainOptions()}
                value={watch("domainId")}
                register={register}
                onChange={(e: any, i: any) => {
                  setValue("domainId", e);
                }}
              />
            </div>
            <div className="grid grid-cols-2 items-start gap-3 pb-3">
              <div className="textareacss">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Description
                </label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <textarea
                      className="p-2 w-full bg-chatlook-secondary placeholder-[#9ba1af] text-xs text-ev-white h-10 rounded outline-none border-[1px] border-[#222A40] focus:border-chatlook-secondary"
                      placeholder="Enter Description"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="proof-sec">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Upload Banner Image ( Max image size 5MB )
                  {/*Upload Banner Image ( Max image size 5MB - Resolution 381px **/}
                  {/*473px )*/}
                  {/*<span*/}
                  {/*  className={*/}
                  {/*    (imageData?.width === 381 && imageData?.height === 473) ||*/}
                  {/*    (imageData?.width === 0 && imageData?.height === 0)*/}
                  {/*      ? `text-sm text-ev-lightgray pb-[4px]`*/}
                  {/*      : `text-sm text-red-600  pb-[4px]`*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*</span>*/}
                  {` ${imageData?.width} x ${imageData?.height} `}
                </label>

                <div className="file-input">
                  {/* <Controller
                    name="image"
                    control={control}
                    render={({ field }) => ( */}
                  <>
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      className="file-input__input"
                      onChange={(e: any) => {
                        try {
                          const file: any = e.target.files?.[0];
                          const maxSize = 5 * 1024 * 1024;
                          if (file && file?.size < maxSize) {
                            const reader = new FileReader();
                            reader.onload = (e: any) => {
                              const image: any = new Image();
                              image.src = e.target.result;
                              image.onload = () => {
                                setImageData({
                                  ...imageData,
                                  size: file.size,
                                  width: image.width,
                                  height: image.height,
                                });
                                setImage(URL.createObjectURL(file));

                                setValue("image", file);
                              };
                            };
                            reader.readAsDataURL(file);
                          }
                          setImageData({ ...imageData, size: file?.size });
                        } catch (err) {
                          console.error("======", err);
                        }
                      }}
                    />
                    <label
                      className="file-input__label"
                      htmlFor="file-input"
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      onClick={(e) => {
                        if (image) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                    >
                      <span className="font-semibold pt-2 text-[#037756] text-xs">
                        {watch("image")?.name
                          ? `${watch("image")?.name}`
                          : "Click to Upload Banner Image"}{" "}
                        {image ? null : (
                          <Icon
                            name="UploadIcon"
                            className="w-10 h-10 m-auto"
                          />
                        )}
                      </span>
                      {image ? (
                        <>
                          {/* <img
                                src={image}
                                alt="Selected file"
                                className="w-100 m-auto py-2 h-40"
                              /> */}
                          <IoMdCloseCircle
                            size={25}
                            className="absolute right-1 top-1"
                            style={{ color: "#037756" }}
                            onClick={(e) => closeImage(e)}
                          />
                        </>
                      ) : null}
                    </label>
                  </>
                  {/* )}
                  > */}
                </div>
                {errors?.image ? (
                  <span className="text-red-500 text-sm">
                    {errors?.image?.message}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-2 items-start gap-3 pb-3">
              <div className="ckeditor-container">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Rules
                </label>
                <Editor
                  style={{ height: 100 }}
                  placeholder="Enter rules"
                  value={watch("rules")}
                  onTextChange={(e) => setValue("rules", e.htmlValue)}
                />
              </div>
              <div className="ckeditor-container">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Terms & Conditions
                </label>
                <Editor
                  style={{ height: 100 }}
                  placeholder="Enter Terms & Conditions"
                  value={watch("tC")}
                  onTextChange={(e) => setValue("tC", e.htmlValue)}
                />
              </div>
            </div>
            <div className="grid pt-2">
              <div className="flex items-center gap-2">
                <label className={`text-sm text-ev-secondary pb-[4px]`}>
                  Status :
                </label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value === 1}
                      onChange={(checked: any) =>
                        setValue("status", checked ? 1 : 0)
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <Button type="submit" className="mt-[10px] !h-9 !text-sm">
                {loading ? (
                  <Loader loaderClassName="create-edit-bonus" />
                ) : id ? (
                  "Update"
                ) : (
                  "Add"
                )}
              </Button>
              <Button
                variant="Transaparent"
                className="mt-[10px]  !text-sm !h-9"
                onClick={() => {
                  reset();
                  setImage(id ? currentPromotion?.image_url : null);
                  setImageData({
                    width: 0,
                    height: 0,
                    size: 0,
                  });
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PromotionForm;
