import { useEffect, useState, Fragment, useRef } from "react";
import { MdEdit } from "react-icons/md";
import { CiFilter } from "react-icons/ci";

// UI IMPORT
import {
  Button,
  ControlledInput,
  Select,
  Table,
  TableRow,
  Switch,
} from "../../ui";

// PROJECT IMPORT
import {
  useGetDomainMutation,
  useUpdateDomainStatusMutation,
} from "./domainIdApi";
import { setDomainIdStatus, useDomainData } from "./domainIdSlice";
import { FILTER } from "../../constant";
import AddDomainModal from "./AddDomainModal";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { responseToaster } from "../../helperFunctions";
import { useDispatch } from "react-redux";
import DeleteDomainModal from "./DeleteDomainModal";
import { useUser } from "../auth/authSlice";

const getDefaultDates = () => {
  const date = new Date();
  return {
    date: [new Date(date.setDate(date.getDate() - 30)), new Date()],
  };
};

const initialValues = {
  client: "ALL",
  ...getDefaultDates(),
};

const DomainId = () => {
  const userData: any = useUser();
  const domainData = useDomainData();
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [statusManualLoading, setStatusManualLoading] = useState<any>(false);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(FILTER);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [showDiv, setShowDiv] = useState(true);
  const isInitialMount = useRef(true);

  const [getDomain, { isLoading }] = useGetDomainMutation();

  const [updateDomainStatus] = useUpdateDomainStatusMutation();

  const statusOption = [
    { value: 1, label: "Active", activeClassName: "green" },
    { value: 0, label: "Deactive", activeClassName: "red" },
  ];

  useEffect(() => {
    setTableData(domainData?.data?.length ? [...domainData?.data] : []);
  }, [domainData]);

  const onUpdateStatusManual = async (payload: any) => {
    try {
      setStatusManualLoading(payload?.id);

      const res: any = await updateDomainStatus(payload).unwrap();

      const index = domainData?.data?.findIndex(
        (item: any) => item?.id === payload?.id
      );
      if (index > -1) {
        const newData = [...domainData.data];
        newData[index] = {
          ...newData[index],
          is_active: payload?.value,
        };
        dispatch(setDomainIdStatus({ ...domainData, data: newData }));
      }
      responseToaster(res);

      setStatusManualLoading(false);
    } catch (err) {
      setStatusManualLoading(false);
    }
  };

  const columns = [
    {
      title: "Domain ID",
      name: "id",
    },
    {
      title: "Domain Name",
      name: "domain_name",
      sortable: false,
      sortableKey: "domain_name",
    },
    {
      title: "Currency",
      name: "currency",
      sortable: false,
      sortableKey: "currency",
    },
    {
      title: "Symbol",
      name: "currency_symbol",
      sortable: false,
      sortableKey: "currency_symbol",
    },
  ];
  if (userData?.access_level?.DOMAIN_STATUS_UPDATE) {
    const newCol: any = {
      title: "Status",
      name: "status",
      sortable: false,
      sortableKey: "is_active",
      Cell: (data: any) => {
        return (
          <div>
            {/* <ToggleSwitch
              isLoading={isStatusLoading === data?.domain_id}
              value={data?.is_active ? 1 : 0}
              options={statusOption}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    value: value,
                    id: data?.id,
                  };
                  await onUpdateStatus(payload);
                } catch (err) {}
              }}
            /> */}
            <Switch
              checked={!!data?.is_active}
              isLoading={statusManualLoading === data?.id}
              onChange={async (value: any, e: any) => {
                e.stopPropagation();
                try {
                  const payload = {
                    value: value ? 1 : 0,
                    id: data?.id,
                  };
                  await onUpdateStatusManual(payload);
                } catch (err) {}
              }}
            />
          </div>
        );
      },
    };
    columns?.splice(4, 0, newCol);
  }
  if (
    userData?.access_level?.DOMAIN_UPDATE ||
    userData?.access_level?.DOMAIN_DELETE
  ) {
    const newCol: any = {
      title: "Actions",
      name: "actions",
      Cell: (item: any) => {
        return (
          <div className="flex items-center gap-2">
            {userData?.access_level?.DOMAIN_UPDATE ? (
              <Button
                className="!h-7 w-8 !p-0"
                onClick={() => {
                  setIsModalOpen(true);
                  setEditData(item);
                }}
              >
                <MdEdit />
              </Button>
            ) : null}
            {/* {userData?.access_level?.DOMAIN_DELETE ? 
              <Button
                className="!h-7 w-8 !p-0"
                variant="Error"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeleteData(item);
                }}
              >
                <MdDelete />
              </Button>
            :null} */}
          </div>
        );
      },
    };
    columns?.push(newCol);
  }
  const { register, handleSubmit, setValue, reset, getValues, control } =
    useForm<any>({
      defaultValues: {
        ...initialValues,
      },
    });

  const onGetData = async () => {
    try {
      const values = getValues();

      const payload: any = {
        ...filter,
        filter_data: {
          searchData: searchTerm,
          status: values?.status,
          currency: values?.currency,
        },
      };
      delete payload["isFilter"];
      const res: any = await getDomain(payload);
      if (filter?.isFilter) {
        responseToaster(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const prepareDomainIdOptions = () => {
    return domainData?.currency?.map((item: any) => ({
      value: item?.currency ?? "%",
      label: item?.currency ?? "Empty",
    }));
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const debounceFunction = setTimeout(() => {
      setFilter({ ...filter, page_no: 1 });
    }, 1000);

    return () => clearTimeout(debounceFunction);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <div className="">
        <div className="cardcss mb-3 flex items-center justify-between pb-3">
          <ControlledInput
            name="searchData"
            className="w-100"
            rightIcon={searchTerm !== "" ? "Closeicon" : null}
            righticonClick={() => setSearchTerm("")}
            placeholder="Enter search value"
            control={control}
            value={searchTerm}
            type="text"
            autoComplete="off"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
          <div className="flex gap-2">
            <Button
              type="button"
              variant="Transaparent"
              onClick={() => {
                setShowDiv(!showDiv); // Toggle the value
              }}
            >
              <CiFilter className="text-lg" />
            </Button>
            {/* {userData?.access_level?.DOMAIN_ADD ? (
              <Button onClick={() => setIsModalOpen(!isModalOpen)}>
                Add Domain
              </Button>
            ) : null} */}
          </div>
        </div>
        {showDiv && (
          <div className="cardcss mb-3">
            <form
              className="flex flex-wrap gap-2 items-end"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                name="status"
                label="Status"
                placeholder="All"
                options={statusOption}
                register={register}
              />
              <Select
                name="currency"
                label="Currency"
                placeholder="All"
                options={prepareDomainIdOptions()}
                register={register}
              />
              <Button type="submit" className="!h-10 !text-sm">
                Apply
              </Button>
              <Button
                variant="Transaparent"
                className="!text-sm !h-10 "
                onClick={() => {
                  reset();
                  setFilter({ ...FILTER, isFilter: true });
                  isInitialMount.current = true;
                  setSearchTerm("");
                  setValue("searchData", "");
                }}
              >
                Clear
              </Button>
            </form>
          </div>
        )}

        <div>
          <div className="App tableborder bg-chatlook-white">
            <Table
              columns={columns}
              isLoading={isLoading}
              data={domainData?.data}
              isExpendable={false}
              setData={setTableData}
              count={domainData?.total_item || 0}
              pagination={filter}
              handlePaginationChange={(pagination) => {
                setFilter({
                  ...filter,
                  ...pagination,
                  isFilter: false,
                });
              }}
            >
              {tableData?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <TableRow
                      columns={columns}
                      item={item}
                      isExpendable={false}
                    />
                  </Fragment>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      <AddDomainModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        editData={editData}
      />
      <DeleteDomainModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteData({});
        }}
        onGet={() => setFilter({ ...filter, isFilter: false })}
        deleteData={deleteData}
      />
    </>
  );
};

export default DomainId;
